import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const FileText: FC<IIconProps> = ({ className, width = 25, height = 24, color, viewBox = '0 0 25 24' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.9856 3V7C14.9856 7.55228 15.4333 8 15.9856 8H19.9856'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.9856 3V7C14.9856 7.55228 15.4333 8 15.9856 8H19.9856'
        stroke='white'
        stroke-opacity='0.2'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.9856 21H7.9856C6.88103 21 5.9856 20.1046 5.9856 19V5C5.9856 3.89543 6.88103 3 7.9856 3H14.9856L19.9856 8V19C19.9856 20.1046 19.0902 21 17.9856 21Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.9856 21H7.9856C6.88103 21 5.9856 20.1046 5.9856 19V5C5.9856 3.89543 6.88103 3 7.9856 3H14.9856L19.9856 8V19C19.9856 20.1046 19.0902 21 17.9856 21Z'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9856 9H10.9856'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9856 9H10.9856'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9856 13H15.9856'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9856 13H15.9856'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9856 17H15.9856'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.9856 17H15.9856'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
