/* eslint-disable react/jsx-pascal-case */
import { FC, useState, ChangeEvent, useEffect } from 'react';
import {
  AccountFormWrap,
  Bar,
  PrimaryButton,
  SecondaryButton,
  StyledBox,
  StyledMenu,
  StyledRow,
  StyledSelect,
  StyledTextField,
  StyledUnknownBox,
  UserAvatar,
  UserForm,
  UserInfo,
} from './account-form.s';
import Styles, { Typography } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import colors from '../../../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useAuthUpdateMeMutation,
  useGetDistrictQuery,
  useGetMeQuery,
  useGetRegionQuery,
} from '../../../../../store/services/authApi';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../../store/slices/authSlice';

interface IAccountFormProps {
  setFormStatus: (status: string) => void;
  theme: string;
}

export const AccountForm: FC<IAccountFormProps> = ({ setFormStatus, theme }) => {
  const [t, i18n] = useTranslation('global');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { data: getMe, refetch } = useGetMeQuery(null);
  const [updateMe, { isLoading }] = useAuthUpdateMeMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isAvatarChanged, setIsAvatarChanged] = useState(false);
  const getRegion = useGetRegionQuery(i18n.language);
  const [district, setDistrict] = useState(1);
  const getDistrict = useGetDistrictQuery({ region_id: district, lang: i18n.language });
  const dispatch = useDispatch();

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      formik.setFieldValue('avatar', file);
      setIsAvatarChanged(true);
    }
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleReset = () => {
    setSelectedImage(null);
    formik.setFieldValue('avatar', '');
  };

  const handleFormikReset = () => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      firstName: getMe?.first_name || '',
      lastName: getMe?.last_name || '',
      fatherName: getMe?.father_name || '',
      institution_number: getMe?.institution_number || '',
      region: getMe?.region || 1,
      city: getMe?.district || 1,
      avatar: getMe?.avatar || '',
    }));
  };

  const accountSettingsValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Kamida 2 ta harf kiriting')
      .max(50, "U juda uzun ko'pida 50 ta harf")
      .required("Maydon to'ldirilishi shart"),
    lastName: Yup.string()
      .min(2, 'Kamida 2 ta harf kiriting')
      .max(50, "U juda uzun ko'pida 50 ta harf")
      .required("Maydon to'ldirilishi shart"),
    avatar: Yup.mixed(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: getMe?.first_name || '',
      fatherName: getMe?.father_name || '',
      institution_number: getMe?.institution_number || '',
      lastName: getMe?.last_name || '',
      region: getMe?.region || 1,
      city: getMe?.district || 1,
      avatar: getMe?.avatar || '',
    },
    validationSchema: accountSettingsValidationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        console.log(values.region, values.city);
        formData.append('last_name', values.lastName);
        formData.append('first_name', values.firstName);
        formData.append('institution_number', values.institution_number);
        formData.append('father_name', values.fatherName);
        formData.append('region', values.region.toString());
        formData.append('district', values.city.toString());
        if (isAvatarChanged) {
          formData.append('avatar', values.avatar);
        }
        const response = await updateMe(formData);
        if (response) {
          refetch();
          let newAvatarUrl = response.data.avatar.replace('http://classcom.jscorp.uz/', '');
          setSnackbarMessage('settings.snackbar');
          setSnackbarOpen(true);
          dispatch(
            setUser({
              avatar: newAvatarUrl,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              institution_number: response.data.institution_number,
              phone: response.data.phone,
              role: response.data.role,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      firstName: getMe?.first_name || '',
      lastName: getMe?.last_name || '',
      fatherName: getMe?.father_name || '',
      institution_number: getMe?.institution_number || '',
      region: getMe?.region || 1,
      city: getMe?.district || 1,
      avatar: getMe?.avatar || '',
    }));
  }, [getMe, refetch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getRegion;
        await getDistrict.refetch();
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchData();
  }, [getRegion]);

  useEffect(() => {
    setDistrict(formik.values.region);
  }, [formik.values.region]);

  useEffect(() => {
    if (getDistrict.data && getMe?.district) {
      const districtExists = getDistrict.data.districts.some(
        (district: any) => district.id === getMe.district
      );

      if (districtExists) {
        formik.setFieldValue('city', getMe.district);
      } else {
        formik.setFieldValue('city', getDistrict.data.districts[0].id);
      }
    } else if (getDistrict.data) {
      formik.setFieldValue('city', getDistrict.data.districts[0].id);
    }
  }, [getDistrict.data, getMe?.district]);

  return (
    <AccountFormWrap>
      <Styles.Row>
        <UserInfo theme={theme}>
          <Styles.Row gap={26} wrap={'nowrap'}>
            <UserAvatar>
              {selectedImage ? (
                <StyledBox selectedImage={selectedImage} />
              ) : getMe?.avatar ? (
                formik.values.avatar === '' ? (
                  <StyledUnknownBox avatar={null} />
                ) : (
                  <StyledUnknownBox avatar={getMe.avatar} />
                )
              ) : (
                <StyledUnknownBox avatar={null} />
              )}
              <input
                id='fileInput'
                type='file'
                accept='image/*'
                name='avatar'
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
            </UserAvatar>
            <Styles.Row gap={16}>
              <Styles.Row gap={16}>
                <PrimaryButton onClick={handleButtonClick} variant='contained'>
                  <Typography.paragraphSmallSemiBold color='white'>
                    {t('settings.buttons.photo')}
                  </Typography.paragraphSmallSemiBold>
                </PrimaryButton>
                <SecondaryButton variant='text' onClick={handleReset} disabled={!selectedImage}>
                  <Typography.paragraphSmallSemiBold color='typography-muted'>
                    {t('settings.buttons.delete')}
                  </Typography.paragraphSmallSemiBold>
                </SecondaryButton>
              </Styles.Row>
              <Styles.Row align_content={'flex-end'}>
                <Typography.paragraph color='typography-muted'>
                  {t('settings.buttons.info')}
                </Typography.paragraph>
              </Styles.Row>
            </Styles.Row>
          </Styles.Row>
        </UserInfo>
        <UserForm onSubmit={formik.handleSubmit}>
          <StyledRow gap={16}>
            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} gap={8}>
              <Styles.Column size={4} difference={0}>
                <Styles.Row direction={'column'} gap={4}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor="user's name and surname">
                    {t('settings.inputs.name')}
                    <Typography.paragraphSmall as={'span'} color='danger-400'>
                      *
                    </Typography.paragraphSmall>
                  </Typography.labelSm>
                  <StyledTextField
                    placeholder='John'
                    id="user's name and surname"
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.firstName) && Boolean(formik.touched.firstName)}
                  />
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <Typography.paragraphSmall color='danger-600'>
                      {formik.errors.firstName}
                    </Typography.paragraphSmall>
                  ) : (
                    ''
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={4} difference={0}>
                <Styles.Row direction={'column'} gap={4}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor="user's name and surname">
                    {t('settings.inputs.surname')}
                    <Typography.paragraphSmall as={'span'} color='danger-600'>
                      *
                    </Typography.paragraphSmall>
                  </Typography.labelSm>
                  <StyledTextField
                    placeholder='Doe'
                    id="user's name and surname"
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.lastName) && Boolean(formik.touched.lastName)}
                  />
                  {formik.errors.lastName && formik.touched.lastName ? (
                    <Typography.paragraphSmall color='danger-600'>
                      {formik.errors.lastName}
                    </Typography.paragraphSmall>
                  ) : (
                    ''
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={4} difference={0}>
                <Styles.Row direction={'column'} gap={4}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor="user's name and surname">
                    {t('auth.userReg.inputs.fatherName')}
                    <Typography.paragraphSmall as={'span'} color='danger-600'>
                      *
                    </Typography.paragraphSmall>
                  </Typography.labelSm>
                  <StyledTextField
                    placeholder='John'
                    id="user's name and surname"
                    name='fatherName'
                    value={formik.values.fatherName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.fatherName) && Boolean(formik.touched.fatherName)}
                  />
                  {formik.errors.fatherName && formik.touched.fatherName ? (
                    <Typography.paragraphSmall color='danger-600'>
                      {formik.errors.fatherName}
                    </Typography.paragraphSmall>
                  ) : (
                    ''
                  )}
                </Styles.Row>
              </Styles.Column>
            </Styles.Row>
            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} gap={8}>
              <Styles.Column size={4} difference={0}>
                <Styles.Row direction={'column'} gap={4}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor='region'>
                    {t('settings.inputs.region')}
                    <Typography.paragraphSmall as={'span'} color='danger-600'>
                      *
                    </Typography.paragraphSmall>
                  </Typography.labelSm>
                  <StyledSelect
                    id='region'
                    name='region'
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.region) && Boolean(formik.touched.region)}
                    displayEmpty
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '300px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {getRegion?.data?.results &&
                      getRegion?.data?.results.map((region: any, index: number) => (
                        <StyledMenu key={index} value={region.id}>
                          {region.region}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.errors.region && formik.touched.region ? (
                    <Typography.paragraphSmall color='danger-600'>
                      {formik.errors.region}
                    </Typography.paragraphSmall>
                  ) : (
                    ''
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={4} difference={0}>
                <Styles.Row direction={'column'} gap={4}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor='city'>
                    {t('auth.userReg.inputs.city')}
                    <Typography.paragraphSmall as={'span'} color='danger-600'>
                      *
                    </Typography.paragraphSmall>
                  </Typography.labelSm>
                  <StyledSelect
                    id='city'
                    name='city'
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.city) && Boolean(formik.touched.city)}
                    displayEmpty
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '300px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {getDistrict.data &&
                      getDistrict.data.districts &&
                      getDistrict.data.districts.map((district: any, index: number) => (
                        <StyledMenu key={index} value={district.id}>
                          {district.district}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.errors.city && formik.touched.city ? (
                    <Typography.paragraphSmall color='danger-600'>
                      {formik.errors.city}
                    </Typography.paragraphSmall>
                  ) : (
                    ''
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={4} difference={0}>
                <Styles.Row direction={'column'} gap={4}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor="user's name and surname">
                    {t('auth.userReg.inputs.institutionName')}
                    <Typography.paragraphSmall as={'span'} color='danger-600'>
                      *
                    </Typography.paragraphSmall>
                  </Typography.labelSm>
                  <StyledTextField
                    placeholder='John'
                    id="user's name and surname"
                    name='institution_number'
                    value={formik.values.institution_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.errors.institution_number) && Boolean(formik.touched.institution_number)}
                  />
                  {formik.errors.institution_number && formik.touched.institution_number ? (
                    <Typography.paragraphSmall color='danger-600'>
                      {formik.errors.institution_number}
                    </Typography.paragraphSmall>
                  ) : (
                    ''
                  )}
                </Styles.Row>
              </Styles.Column>
            </Styles.Row>
            <Styles.Row gap={16} wrap={'nowrap'} content={{ xs: 'space-between', md: 'flex-end' }}>
              <Button
                variant='text'
                type='button'
                onClick={() => {
                  handleFormikReset();
                }}
                sx={{
                  width: '150px',
                  padding: '10px',
                  backgroundColor: `${colors['opacity-primary16']}`,
                }}>
                <Typography.paragraphSmallSemiBold color='primary-400'>
                  {t('settings.buttons.cancel')}
                </Typography.paragraphSmallSemiBold>
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={isLoading}
                sx={{
                  width: '150px',
                  padding: '10px',
                }}>
                <Typography.paragraphSmallSemiBold color='white'>
                  {t('settings.buttons.save')}
                </Typography.paragraphSmallSemiBold>
              </Button>
            </Styles.Row>
          </StyledRow>
        </UserForm>
      </Styles.Row>
      <Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </AccountFormWrap>
  );
};
