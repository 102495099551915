import { Container } from './container';
import { Modal } from './modal';
import { Table } from './table';
import { Uploader } from './fileUploader';
import { RichTextEditor } from './richTextEditor';
import { Manual } from './manual';

const CommonList = {
  Table,
  Modal,
  Container,
  Uploader,
  RichTextEditor,
  Manual,
};

export default CommonList;
