import { MEDIA } from '../URLs';
import { api } from './api';

export const mediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: () => ({
        url: MEDIA,
        method: 'GET',
      }),
      providesTags: ['media'],
    }),
    getMediaById: builder.query<any, { id: string; lang: string }>({
      query: ({ id, lang }) => ({
        url: MEDIA + `${id}/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getMediaById'],
    }),
    getTopicMediaById: builder.query<any, { id: string; lang: string }>({
      query: ({ id, lang }) => ({
        url: MEDIA + `?id=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getMediaById'],
    }),
    deleteMedia: builder.mutation({
      query: (id: number) => ({
        url: MEDIA + `?id=${id}`,
        method: 'DELETE',
        headers: {
        },
      }),
      invalidatesTags: ['getMediaById'],
    }),
  }),
});

export const { useGetMediaQuery, useGetMediaByIdQuery, useGetTopicMediaByIdQuery, useDeleteMediaMutation } = mediaApi;
