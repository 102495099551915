/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import Styles, { GlobalStyles, Typography } from '../../../../../../styles';
import { useNavigate } from 'react-router-dom';
import {
  BtnWrapper,
  PhoneWrapper,
  PrimaryButton,
  ResetPasswordFormWrap,
  SecondaryButton,
  StyledPhoneFormat,
  StyledTypographyParagraph,
} from './resetPassword-form.s';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useResetPasswrodMutation } from '../../../../../../store/services/updatePasswordApi';
import { setPhoneNumber } from '../../../../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icons from '../../../../../../assets/icons';

interface ILoginFormProps {}

export const ResetPasswordForm: FC<ILoginFormProps> = (props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [resetPasswrod, { isLoading }] = useResetPasswrodMutation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(9, 'auth.resetPass.errors.phone.min')
        .required('auth.resetPass.errors.phone.required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await resetPasswrod({
          phone: `${values.phoneNumber}`,
          lang: i18n.language,
        }).unwrap();
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        dispatch(
          setPhoneNumber({
            phone: `${values.phoneNumber}`,
          })
        );
        setTimeout(() => {
          navigate('/author/reset-password-verification');
        }, 1000);
      } catch (error: any) {
        setSnackbarMessage(error.data.phone ? error.data.phone : error.data.message);
        setSnackbarOpen(true);
      }
    },
  });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleBack = () => {
    navigate('/login');
  };

  return (
    <ResetPasswordFormWrap>
      <Styles.Row gap={26}>
        <Styles.Row gap={6}>
          <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
            <Typography.h3 color='typography-body'>{t('auth.resetPass.title')} 🔒</Typography.h3>
          </Styles.Row>
          <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
            <StyledTypographyParagraph color='typography-body'>
              {t('auth.resetPass.subTitle')}
            </StyledTypographyParagraph>
          </Styles.Row>
        </Styles.Row>
        <form onSubmit={formik.handleSubmit}>
          <Styles.Row gap={16}>
            <PhoneWrapper gap={4}>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.placeholderSm
                  as={'label'}
                  htmlFor='reset password phone number'
                  color='typography-body'>
                  {t('auth.resetPass.numberTitle')}
                </Typography.placeholderSm>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <StyledPhoneFormat
                  id='reset password phone number'
                  name='phoneNumber'
                  type='text'
                  format='+998 (##) ###-##-##'
                  allowEmptyFormatting
                  mask='_'
                  value={formik.values.phoneNumber}
                  onValueChange={(values) =>
                    formik.setValues({
                      ...formik.values,
                      phoneNumber: values.value,
                    })
                  }
                  errorColor={
                    Boolean(formik.errors.phoneNumber) && Boolean(formik.touched.phoneNumber)
                  }
                />
              </Styles.Row>
              {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                <Typography.paragraphSmall color='danger-600'>
                  {t(formik.errors.phoneNumber)}
                </Typography.paragraphSmall>
              ) : (
                ''
              )}
            </PhoneWrapper>
            <BtnWrapper gap={10} content={{ xs: 'space-between', md: 'center' }} wrap={'nowrap'}>
              <SecondaryButton variant='text' onClick={handleBack}>
                <Typography.h6 color='primary-500'>{t('auth.resetPass.cancelBtn')}</Typography.h6>
              </SecondaryButton>
              <PrimaryButton type='submit' variant='contained' disabled={isLoading}>
                {isLoading ? (
                  <Icons.Loader />
                ) : (
                  <Typography.h6 color='white'>{t('auth.resetPass.nextBtn')}</Typography.h6>
                )}
              </PrimaryButton>
            </BtnWrapper>
          </Styles.Row>
        </form>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </ResetPasswordFormWrap>
  );
};
