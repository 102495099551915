import { useTranslation } from 'react-i18next';
import { Spin } from '../spin';
import { Box, Button, Modal } from '@mui/material';
import { StyledBox } from './delete-modal.s';
import Styles, { Typography } from '../../styles';

type CustomModalProps = {
  open: boolean;
  text?: string;
  okText?: string;
  headerText?: string;
  click: Function;
  loading?: boolean;
  handleCancel: () => void;
};

export const DeleteModal = ({
  click,
  loading,
  open,
  handleCancel,
  text,
  okText,
  headerText,
}: CustomModalProps) => {
  const [t] = useTranslation('global');

  return (
    <Modal open={Boolean(open)} onClose={handleCancel}>
      <StyledBox >
        <Styles.Row gap={16}>
          <Styles.Row content={'center'} gap={8}>
            <Typography.h3 color='typography-heading'>
              {headerText ? headerText : t('table.deleteModalHeaderText')}
            </Typography.h3>
            <Typography.paragraph color='typography-body'>
              {text ? text : t('table.deleteModalText')}
            </Typography.paragraph>
          </Styles.Row>
          <Styles.Row wrap={'nowrap'} gap={16}>
            <Button  
              onClick={handleCancel}
              fullWidth
              variant='text'
            >
              {t('table.no')}
            </Button>
            <Button 
              onClick={() => click()} disabled={loading}
              fullWidth
              variant='contained'
            >
              {loading && <Spin size='20px' />} <Box mr='10px' />{' '}
              {okText ? okText : t('table.delete')}
            </Button>
          </Styles.Row>
        </Styles.Row>
      </StyledBox>
    </Modal>
  );
};
