import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const ChartDots: FC<IIconProps> = ({ className, width = 18, height = 18, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.25 2.25V15.75H15.75'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='6.75'
        cy='6.75'
        r='1.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='14.25'
        cy='5.25'
        r='1.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='10.5'
        cy='11.25'
        r='1.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.62012 7.96484L9.37512 10.1248'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.3164 9.99614L13.4442 6.55664'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
