/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { Select, SelectChangeEvent } from '@mui/material';
import Styles, { Typography } from '../../../../../../styles';
import colors from '../../../../../../constants';
import { ItemsWrap, NavBtn, StyledFormControl, StyledMenu } from './header.s';
import { TodayDate } from '../../../../../../lib/getDay';
import { useTranslation } from 'react-i18next';
import { useGetAllQuartersQuery } from '../../../../../../store/services/quarterApi';
import { useGetWeeksQuery } from '../../../../../../store/services/weekApi';
import { useNavigate } from 'react-router-dom';
import { useGetClassGroupQuery } from '../../../../../../store/services/classApi';
import { QuarterInfo } from '../../../../../../types/quarter';
import { WeekData } from '../../../../../../types/week';

interface IHeader {
  setWeeksData: (state: WeekData[]) => void;
  theme: string;
  quarter: number | null;
  isHoliday: boolean;
  scheduleQuarter: number | null;
  scheduleWeek: number | null;
  setScheduleQuarter: (state: number | null) => void;
  setScheduleWeek: (state: number | null) => void;
}

export const Header: FC<IHeader> = ({
  setWeeksData,
  quarter,
  isHoliday,
  theme,
  scheduleQuarter,
  scheduleWeek,
  setScheduleQuarter,
  setScheduleWeek,
}) => {
  const [t, i18n] = useTranslation('global');
  const { data: quarterData } = useGetAllQuartersQuery();
  const { data: weekData } = useGetWeeksQuery(scheduleQuarter, { skip: scheduleQuarter === null });
  const [newDate] = useState<any | null>(null);
  const today = TodayDate();
  const { data: classGroupData } = useGetClassGroupQuery(i18n.language);
  const navigate = useNavigate();

  useEffect(() => {
    setWeeksData(weekData);
  }, [weekData]);

  return (
    <Styles.Row direction={'column'} gap={16}>
      <Styles.Row align_items={'center'} content={'center'}>
        <Styles.Row width='auto' direction={'column'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {newDate ? (
              <>
                {newDate.day} - {t(`${newDate.month}`)}, {newDate.year}
              </>
            ) : (
              <>
                {today.day} - {t(`${today.month}`)}, {today.year}
              </>
            )}
          </Typography.h4>
          {isHoliday
            ? ''
            : quarter && (
                <Typography.paragraph color='primary-500'>
                  {quarter} - {t('mainPage.mainHeader.quarter')}
                </Typography.paragraph>
              )}
        </Styles.Row>
      </Styles.Row>

      <Styles.Row content={'space-between'} gap={{ xs: 16 }} wrap={{ xs: 'wrap', sm: 'nowrap' }}>
        <ItemsWrap align_items={'center'} gap={16}>
          {/* Quater Select */}
          <StyledFormControl>
            <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.table.select.quater')}
            </Typography.paragraphSmall>
            <Select
              fullWidth
              value={scheduleQuarter}
              displayEmpty
              renderValue={(value) => {
                if (value === null) {
                  return <>{t('auth.userReg.inputs.placeholder')}</>;
                }
                const selectedQuarter = quarterData?.results.find(
                  (quarter: QuarterInfo) => quarter.id === value
                );
                return selectedQuarter ? selectedQuarter.choices : '';
              }}
              onChange={(event: SelectChangeEvent<unknown>) => {
                setScheduleQuarter(event.target.value as number);
              }}
              sx={{
                backgroundColor: 'white',
                borderRadius: '6px',
              }}
              MenuProps={{
                disableScrollLock: true,
                sx: {
                  height: '250px',
                  width: '250px',
                  '& .MuiPaper-root': {
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#bdbdbd transparent',
                  },
                },
              }}>
              {quarterData?.results?.map((quarter: any, index: number) => (
                <StyledMenu value={quarter.id}>{quarter.choices}</StyledMenu>
              ))}
            </Select>
          </StyledFormControl>
          {/* Week Select */}
          <StyledFormControl>
            <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.table.select.week')}
            </Typography.paragraphSmall>
            <Select
              fullWidth
              disabled={scheduleQuarter === null}
              value={scheduleWeek}
              displayEmpty
              renderValue={(value) => {
                if (value === null) {
                  return <>{t('auth.userReg.inputs.placeholder')}</>;
                }
                const selectedWeek = weekData.find((week: any) => week.id === value);
                return selectedWeek
                  ? `${selectedWeek.week_count} - ${t('plans.main.table.select.week')}`
                  : '';
              }}
              onChange={(event: SelectChangeEvent<unknown>) => {
                setScheduleWeek(event.target.value as number);
              }}
              sx={{
                backgroundColor: 'white',
                borderRadius: '6px',
                '& .MuiSelect-select.Mui-disabled': {
                  WebkitTextFillColor: 'rgba(152, 162, 179, 1)',
                  color: 'var(--grey400)',
                },
              }}
              MenuProps={{
                disableScrollLock: true,
                sx: {
                  height: '250px',
                  width: '250px',
                  '& .MuiPaper-root': {
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#bdbdbd transparent',
                  },
                },
              }}>
              {weekData?.map((week: any, index: number) => (
                <StyledMenu value={week.id}>
                  {week.week_count} - {t('plans.main.table.select.week')}
                </StyledMenu>
              ))}
            </Select>
          </StyledFormControl>
          {/* Type Select */}
        </ItemsWrap>

        {/* Buttons */}
        <ItemsWrap
          content={{ xs: 'space-between', sm: 'flex-end' }}
          align_items={'flex-end'}
          gap={16}>
          <NavBtn
            color={scheduleWeek ? 'extra-white' : 'typography-muted'}
            theme={theme}
            disabled={scheduleWeek ? false : true}
            br={scheduleWeek ? false : true}
            bg={scheduleWeek ? colors['primary-500'] : 'transparent'}
            onClick={() => {
              setScheduleQuarter(null);
              setScheduleWeek(null);
            }}>
            {t('mainPage.sample.backBtn')}
          </NavBtn>
          <NavBtn
            br={classGroupData?.results.length !== 0 ? false : true}
            theme={theme}
            disabled={classGroupData?.results.length === 0}
            onClick={() => navigate('/sample-create')}
            color={classGroupData?.results.length !== 0 ? 'extra-white' : 'typography-muted'}
            bg={classGroupData?.results.length !== 0 ? colors['primary-500'] : 'transparent'}>
            {t('mainPage.sample.addBtn')}
          </NavBtn>
        </ItemsWrap>
      </Styles.Row>
    </Styles.Row>
  );
};
