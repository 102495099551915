import styled from 'styled-components';
import Styles from '../../../styles';
import colors from '../../../constants';
import { Button, Select, Table, TableContainer } from '@mui/material';

export const PlansWrap = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
    @media(max-width: 768px){
      padding: 0px 10px;
    }
  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const SelectWrapper = styled(Styles.Row)`
&& {
  width: 130px;
  @media(max-width: 576px){
    width: 100px;
  }
}
  
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    height: 44px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
      
    }
  }
`;

export const IconButton = styled(Button)`
  && {
    width: auto;
    gap: 8px;
    height: 44px;
    background-color: ${colors['primary-500']};
  }
`;

export const ModalButton = styled(Button)`
  && {
    width: 192px;
    padding: 10px;
    text-transform: capitalize;
    @media(max-width: 768px){
      width: 150px;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;
