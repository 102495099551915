import { CHANGE_MODERATOR, TMR_APPEAL, TMR_FILES } from '../URLs';
import { api } from './api';

interface ChangeModeratorPayload {
  class_groups: number;
  classes: number;
  science: number;
  science_type: any;
}

export const changeModeratorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addApplicationTmr: builder.mutation<any, { payload: ChangeModeratorPayload; lang: string }>({
      query: ({ payload, lang }) => ({
        url: TMR_APPEAL,
        method: 'POST',
        body: payload,
        headers: {
          'accept-language': lang,
        },
      }),
      invalidatesTags: ['tmrAppeal'],
    }),
    getTmrAppeal: builder.query<
      any,
      {
        page?: number;
        lang: string;
        filterParams: {
          status: number;
          class_groups: number;
          classes: number;
          science: number;
          science_type: number;
        };
      }
    >({
      query: ({ page = 1, lang, filterParams = {} }) => {
        let url = `${TMR_APPEAL}?page=${page}&page_size=10`;
        const params = [];
        if (filterParams.status !== 0) {
          params.push(`status=${filterParams.status}`);
        }
        if (filterParams.class_groups !== 0) {
          params.push(`class_groups=${filterParams.class_groups}`);
        }
        if (filterParams.classes !== 0) {
          params.push(`classes=${filterParams.classes}`);
        }
        if (filterParams.science !== 0) {
          params.push(`science=${filterParams.science}`);
        }
        if (filterParams.science_type !== 0) {
          params.push(`science_type=${filterParams.science_type}`);
        }

        if (params.length > 0) {
          url += '&' + params.join('&');
        }

        return {
          url,
          method: 'GET',
          headers: {
            'accept-language': lang,
          },
        };
      },
      providesTags: ['tmrAppeal'],
    }),
    getTmrFilesAppeal: builder.query<
      any,
      {
        page?: number;
        lang: string;
        filterParams: {
          status: number;
          class_groups: number;
          classes: number;
          science: number;
          science_type: number;
        };
      }
    >({
      query: ({ page = 1, lang, filterParams = {} }) => {
        let url = `${TMR_FILES}?page=${page}&page_size=10`;
        const params = [];
        if (filterParams.status !== 0) {
          params.push(`status=${filterParams.status}`);
        }
        if (filterParams.class_groups !== 0) {
          params.push(`class_groups=${filterParams.class_groups}`);
        }
        if (filterParams.classes !== 0) {
          params.push(`classes=${filterParams.classes}`);
        }
        if (filterParams.science !== 0) {
          params.push(`science=${filterParams.science}`);
        }
        if (filterParams.science_type !== 0) {
          params.push(`science_type=${filterParams.science_type}`);
        }

        if (params.length > 0) {
          url += '&' + params.join('&');
        }

        return {
          url,
          method: 'GET',
          headers: {
            'accept-language': lang,
          },
        };
      },
      providesTags: ['tmrAppeal'],
    }),
    addTmrFile: builder.mutation<any, { payload: FormData; lang: string }>({
      query: ({ payload, lang}) => ({
        url: TMR_FILES,
        method: 'POST',
        body: payload,
        headers: {
          'accept-language': lang,
        },
      }),
      invalidatesTags: ['tmrAppeal'],
    }),
    getTmrDataById: builder.query<any, number>({
      query: (id) => ({
        url: `${TMR_APPEAL}?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['tmrAppeal'],
    }),
  }),
});

export const {
  useAddApplicationTmrMutation,
  useAddTmrFileMutation,
  useGetTmrAppealQuery,
  useGetTmrFilesAppealQuery,
  useGetTmrDataByIdQuery
} = changeModeratorApi;
