import styled from 'styled-components';
import Styles, { Typography } from '../../../../../styles';
import colors from '../../../../../constants';
import { Button, MenuItem, Select } from '@mui/material';

export const ThemeFormWrap = styled.form`
  width: 100%;
`;

export const FormWrap = styled(Styles.Row)`
  padding: 24px;
`;

export const UserFormInput = styled(Styles.Row)`
  && {
    justify-content: center;
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
`;

export const StyledTypo4 = styled(Typography.h4)`

`;

export const StyledDiv = styled.div`
  display: inline-flex;
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    gap: 16px;
    &.Mui-selected {
      color: ${colors['white']} !important;
      & svg {
        fill: ${colors['white']} !important;
        stroke: ${colors['white']} !important;
      }
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; 
      color: ${colors['white']} !important; 
      & svg {
        fill: ${colors['white']} !important;
        stroke: ${colors['white']} !important;
      }
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important; 
      & svg {
        fill: ${colors['white']} !important;
        stroke: ${colors['white']} !important;
      }
    }
  }
`;

export const UserButtons = styled(Styles.Row)`
  && {
    padding: 24px;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: space-between;
    };
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 192px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    };
    @media (max-width: 768px) {
      width: 100%;
    };
    .MuiSelect-select {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
`;

export const StyledButton = styled(Button)<{ activeButton: number | null; item: number }>`
  && {
    width: 58px;
    height: 38px;
    border-radius: 6px;
    text-transform: capitalize;
    box-shadow: none;
    background-color: ${({ activeButton, item }) =>
      activeButton === item ? colors['primary-500'] : colors['opacity-primary16']};
  }
`;

export const NavbarFlag = styled.img`
  width: 25px;
  height: 24px;
  border-radius: 100px;
`;
