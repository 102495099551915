/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import {
  ApplicationFormTitle,
  FormWrap,
  StyledMenu,
  StyledMultiSelect,
  StyledSelect,
  ThemeFormWrap,
  UserButtons,
  UserFormInput,
} from './application-add-form.s';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import colors from '../../../../../constants';
import { useAppTheme } from '../../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetAllClassQuery, useGetClassGroupQuery } from '../../../../../store/services/classApi';
import {
  useGetScienceByClassQuery,
  useGetScienceTypeQuery,
} from '../../../../../store/services/scienceApi';
import { useAddApplicationTmrMutation } from '../../../../../store/services/changeModerator';

interface IApplicationAddFormProps {
  setAddStatus: (status: boolean) => void;
}

export const ApplicationAddForm: FC<IApplicationAddFormProps> = ({ setAddStatus }) => {
  const [t, i18n] = useTranslation('global');
  const { theme } = useAppTheme();
  const [classGrp, setClassGrp] = useState(0);
  const [scienceGrp, setScienceGrp] = useState(0);
  const { data: classGroupData } = useGetClassGroupQuery(i18n.language);
  const { data: scienceData } = useGetScienceByClassQuery(
    { id: classGrp, lang: i18n.language },
    { skip: classGrp === 0 }
  );
  const { data: scienceTypesData } = useGetScienceTypeQuery({
    class_group: classGrp || Number(localStorage.getItem('class_group')),
    science: scienceGrp || Number(localStorage.getItem('science')),
    lang: i18n.language,
  });
  const { data: classData } = useGetAllClassQuery();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [changeModeratorData, { isLoading }] = useAddApplicationTmrMutation();

  const formik = useFormik({
    initialValues: {
      gradeType: 0,
      grade: 0,
      science: 0,
      scienceType: 0,
    },
    validationSchema: Yup.object({
      gradeType: Yup.number()
        .min(1, t('mainPage.mainModal.errors.classType'))
        .required(t('mainPage.mainModal.errors.classType')),
      grade: Yup.number()
        .min(1, t('mainPage.mainModal.errors.class'))
        .required(t('mainPage.mainModal.errors.class')),
      science: Yup.number()
        .min(1, t('mainPage.mainModal.errors.subject'))
        .required(t('mainPage.mainModal.errors.subject')),
      scienceType: Yup.number()
        .min(1, t('mainPage.mainModal.errors.subject'))
        .required(t('mainPage.mainModal.errors.subject')),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          class_groups: values.gradeType,
          classes: values.grade,
          science: values.science,
          science_type: values.scienceType,
        };
        await changeModeratorData({ payload, lang: i18n.language }).unwrap();
        setSnackbarOpen(true);
        setSnackbarMessage('mainPage.snackbar.sampleAdded');
        formik.handleReset(undefined);
        setAddStatus(false);
      } catch (error: any) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.data.error || 'mainPage.snackbar.sampleErr');
      }
    },
  });

  return (
    <ThemeFormWrap onSubmit={formik.handleSubmit}>
      <Styles.Row>
        <ApplicationFormTitle gap={8}>
          <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('settings.applicationTitle.title')}
          </Typography.h5>
          <Typography.paragraph color='typography-muted'>
            {t('settings.applicationTitle.subTitle')}
          </Typography.paragraph>
        </ApplicationFormTitle>
        <FormWrap gap={16}>
          <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', md: 'row' }}>
            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.class_group')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledSelect
                  value={formik.values.gradeType}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setClassGrp(event.target.value as number);
                  }}
                  renderValue={(selected: any) => {
                    if (selected === 0) {
                      return <>{t('auth.userReg.inputs.placeholder')}</>;
                    }

                    const selectedClassGroup = classGroupData?.results.find(
                      (group: any) => group.id === selected
                    );
                    return selectedClassGroup ? selectedClassGroup.name : '';
                  }}
                  displayEmpty
                  name='gradeType'
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '280px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {classGroupData?.results &&
                    classGroupData?.results.map((classGroup: any, index: number) => (
                      <StyledMenu key={index} value={classGroup.id}>
                        {classGroup.name}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.gradeType && formik.errors.gradeType ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.gradeType}
                  </Typography.paragraphSmall>
                ) : null}
              </UserFormInput>
            </Styles.Row>

            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.table.select.grade')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledSelect
                  value={formik.values.grade}
                  onChange={formik.handleChange}
                  renderValue={(selected: any) => {
                    if (selected === 0) {
                      return <>{t('auth.userReg.inputs.placeholder')}</>;
                    }

                    const selectedClass = classData?.results.find(
                      (group: any) => group.id === selected
                    );
                    return selectedClass ? selectedClass.name : '';
                  }}
                  displayEmpty
                  name='grade'
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '280px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {classData?.results &&
                    classData?.results.map((classData: any, index: number) => (
                      <StyledMenu key={index} value={classData.id}>
                        {classData.name}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.grade && formik.errors.grade ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.grade}
                  </Typography.paragraphSmall>
                ) : null}
              </UserFormInput>
            </Styles.Row>

            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.lesson')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledSelect
                  value={formik.values.science}
                  disabled={formik.values.gradeType === 0}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setScienceGrp(event.target.value as number);
                  }}
                  renderValue={(selected: any) => {
                    if (formik.values.gradeType === 0) {
                      return <>{t('auth.userReg.inputs.sciencePlaceholder')}</>;
                    }

                    const findScienceName = (id: number) => {
                      const science = scienceData?.results.find((item: any) => item.id === id);
                      return science ? `${science.name} (${science.count})` : '';
                    };

                    return selected ? (
                      findScienceName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  displayEmpty
                  name='science'
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '280px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {scienceData?.results &&
                    scienceData?.results.map((science: any, index: number) => (
                      <StyledMenu
                        key={index}
                        value={science.id}
                        onClick={() => {
                          formik.setFieldValue('classes', []);
                        }}>
                        {science.name} ({science.count})
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.science && formik.errors.science ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.science}
                  </Typography.paragraphSmall>
                ) : null}
              </UserFormInput>
            </Styles.Row>

            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.grade')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledMultiSelect
                  disabled={formik.values.science === 0}
                  value={formik.values.scienceType}
                  onChange={formik.handleChange}
                  renderValue={(selected: any) => {
                    if (formik.values.science === 0) {
                      return <>{t('auth.userReg.inputs.scienceGrpPlaceholder')}</>;
                    }

                    const findScienceName = (id: number) => {
                      const science = scienceTypesData?.results.find((item: any) => item.id === id);
                      return science ? `${science.name} (${science.count})` : '';
                    };

                    return selected ? (
                      findScienceName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  displayEmpty
                  name='scienceType'
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '280px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {scienceTypesData &&
                    scienceTypesData?.results.map((scienceInfo: any, index: number) => (
                      <StyledMenu key={index} value={scienceInfo.id}>
                        {scienceInfo.name} ({scienceInfo.count})
                      </StyledMenu>
                    ))}
                </StyledMultiSelect>
                {formik.touched.scienceType && formik.errors.scienceType ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.scienceType}
                  </Typography.paragraphSmall>
                ) : null}
              </UserFormInput>
            </Styles.Row>
          </Styles.Row>
        </FormWrap>

        <UserButtons gap={16} wrap={'nowrap'}>
          <Styles.Row width='130px'>
            <Button
              variant='text'
              onClick={(e) => {
                formik.handleReset(e);
                setAddStatus(false);
              }}
              sx={{
                width: '150px',
                padding: '10px',
                backgroundColor: `${colors['opacity-primary16']}`,
              }}>
              <Typography.paragraphSmallSemiBold color='primary-400'>
                {t('auth.passVer.cancelBtn')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
          <Styles.Row width='130px'>
            <Button
              disabled={isLoading}
              type='submit'
              variant='contained'
              sx={{
                width: '150px',
                padding: '10px',
              }}>
              <Typography.paragraphSmallSemiBold color='white'>
                {t('settings.buttons.save')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
        </UserButtons>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </ThemeFormWrap>
  );
};
