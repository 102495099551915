import { QUARTER, QUESTION } from '../URLs';
import { api } from './api';

export const questionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query<any, void>({
      query: () => ({
        url: QUESTION,
        method: 'GET',
      }),
      providesTags: ['getQuestions'],
    }),
    addQuestion: builder.mutation<any, FormData>({
      query: (body) => ({
        url: QUESTION,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['addQuestion'],
    }),
    readedAnswer: builder.mutation<any, {id: number, is_read: boolean}>({
      query: ({id, is_read}) => ({
        url: `${QUESTION}?id=${id}`,
        method: 'PATCH',
        body: {is_read}
      }),
      invalidatesTags: ['addQuestion'],
    }),
  }),
});

export const { useGetQuestionsQuery, useAddQuestionMutation , useReadedAnswerMutation } = questionApi;
