import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Users: FC<IIconProps> = ({ className, width = 18, height = 18, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='6.75'
        cy='5.25'
        r='3'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.25 15.75V14.25C2.25 12.5931 3.59315 11.25 5.25 11.25H8.25C9.90685 11.25 11.25 12.5931 11.25 14.25V15.75'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 2.34741C13.3274 2.68729 14.2559 3.88341 14.2559 5.25366C14.2559 6.62392 13.3274 7.82003 12 8.15991'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.75 15.7499V14.2499C15.7422 12.8884 14.8184 11.7028 13.5 11.3624'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
