/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import Styles, { GlobalStyles, Typography } from '../../../../../../styles';
import { useNavigate } from 'react-router-dom';
import { NewPasswordFormWrapper, PrimaryButton, SecondaryButton } from './newPassword-form.s';
import Icons from '../../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import colors from '../../../../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useResetSetPasswrodMutation } from '../../../../../../store/services/updatePasswordApi';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@mui/material';

interface ILoginFormProps {}

export const NewPasswordForm: FC<ILoginFormProps> = (props) => {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const resetPasswordToken = useSelector((state: RootState) => state.auth.reset_password_token);
  const [resetSetPassword, { isLoading }] = useResetSetPasswrodMutation();
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(8, 'auth.passEdit.errors.newPass.min')
        .matches(/[A-Za-z]/, 'auth.passEdit.errors.newPass.matches')
        .required('auth.passEdit.errors.newPass.required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'auth.passEdit.errors.confirmPass.oneOf')
        .required('auth.passEdit.errors.confirmPass.required'),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        await resetSetPassword({
          password: values.newPassword,
          token: resetPasswordToken,
        }).unwrap();
        setSnackbarMessage('auth.passEdit.successNewPass');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      } catch (error: any) {
        setSnackbarMessage('mainPage.snackbar.sampleErr');
        setSnackbarOpen(true);
      }
    },
  });

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <NewPasswordFormWrapper>
      <Styles.Row gap={26}>
        <Styles.Row gap={6}>
          <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
            <Typography.h3 color='typography-body'>{t('auth.passEdit.title')} 🔒</Typography.h3>
          </Styles.Row>
        </Styles.Row>
        <form onSubmit={formik.handleSubmit}>
          <Styles.Row gap={16}>
            <Styles.Row gap={4}>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.placeholderSm color='typography-body'>
                  {t('auth.passEdit.newPass')}
                </Typography.placeholderSm>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <GlobalStyles.StyledTextField
                  placeholder='*******'
                  name='newPassword'
                  type={showNewPassword ? 'text' : 'password'}
                  onFocus={() => setShowNewPassword(true)}
                  onBlur={() => setShowNewPassword(false)}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleToggleNewPasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()}>
                          {showNewPassword ? (
                            <Icons.LockOpen color={colors['typography-muted']} />
                          ) : (
                            <Icons.Lock color={colors['typography-muted']} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Styles.Row>
              {formik.touched.newPassword && formik.errors.newPassword && (
                <Typography.paragraph color='danger-500'>
                  {t(formik.errors.newPassword)}
                </Typography.paragraph>
              )}
            </Styles.Row>
            <Styles.Row gap={4}>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.placeholderSm color='typography-body'>
                  {t('auth.passEdit.confirmPass')}
                </Typography.placeholderSm>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <GlobalStyles.StyledTextField
                  placeholder='*******'
                  name='confirmPassword'
                  value={formik.values.confirmPassword}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onFocus={() => setShowConfirmPassword(true)}
                  onBlur={() => setShowConfirmPassword(false)}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()}>
                          {showConfirmPassword ? (
                            <Icons.LockOpen color={colors['typography-muted']} />
                          ) : (
                            <Icons.Lock color={colors['typography-muted']} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Styles.Row>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <Typography.paragraph color='danger-500'>
                  {t(formik.errors.confirmPassword)}
                </Typography.paragraph>
              )}
            </Styles.Row>
            <Styles.Row
              gap={10}
              direction={{ xs: 'row', md: 'column' }}
              content={{ xs: 'space-between', md: 'flex-start' }}
              wrap={{ xs: 'nowrap', sm: 'wrap' }}>
              <PrimaryButton type='submit' variant='contained' disabled={isLoading} fullWidth>
                {isLoading ? (
                  <Icons.Loader />
                ) : (
                  <Typography.h6 color='white'>{t('auth.passEdit.nextBtn')}</Typography.h6>
                )}
              </PrimaryButton>
              <SecondaryButton
                variant='text'
                onClick={() => navigate('/author/reset-password-verification')}
                fullWidth>
                <Icons.ChevronLeft color={colors['primary-500']} />
                <Typography.h6 color='primary-500'>{t('auth.passEdit.cancelBtn')}</Typography.h6>
              </SecondaryButton>
            </Styles.Row>
          </Styles.Row>
        </form>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </NewPasswordFormWrapper>
  );
};
