/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  ButtonsContainer,
  EmptyChip,
  EmptyTitleWrapper,
  PrimaryButton,
  ScienceChip,
  StateChip,
  StyledMenu,
  StyledSelect,
  StyledTable,
  StyledTableContainer,
  TableWrapper,
  ThemeFormWrap,
} from './application-form.s';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import mock from '../../../../../mock';
import { useGetTmrAppealQuery } from '../../../../../store/services/changeModerator';
import { ApplicationAddForm } from '../application-add-form';
import {
  Pagination,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Icons from '../../../../../assets/icons';
import { Common } from '../../../../../components';
import { useAppTheme } from '../../../../../hooks';
import {
  useGetScienceByClassQuery,
  useGetScienceTypeQuery,
} from '../../../../../store/services/scienceApi';
import { useGetAllClassQuery, useGetClassGroupQuery } from '../../../../../store/services/classApi';
import { TmrAppeal } from '../../../../../types/settings';

interface IApplicationFormProps {
  setFormStatus: (status: string) => void;
}

const getHeaderTitle = (key: string, t: Function) => {
  const titleMap: Record<string, string> = {
    number: 'plans.main.table.title.number',
    classType: 'mainPage.mainTable.header.classType',
    grade: 'plans.main.table.title.grade',
    lesson: 'plans.main.table.title.lesson',
    lessonType: 'auth.userReg.inputs.gradeCaps',
    status: 'payment.main.table.status',
  };
  return t(titleMap[key] || '');
};

export const ApplicationForm: FC<IApplicationFormProps> = ({ setFormStatus }) => {
  const [t, i18n] = useTranslation('global');
  const [selectedStatusId, setSelectedStatusId] = useState(0);
  const [selectedClassTypeId, setSelectedClassTypeId] = useState(0);
  const [selectedClassId, setSelectedClassId] = useState(0);
  const statusData = mock.Settings.filterStatus;
  const [selectedScienceId, setSelectedScienceId] = useState(0);
  const [selectedScienceTypeId, setSelectedScienceTypeId] = useState(0);
  const { theme } = useAppTheme();
  const [addStatus, setAddStatus] = useState(false);
  const [page, setPage] = useState(1);
  const Settings = mock.Settings.applicationFormTable;
  const rowsPerPage = 10;
  const {
    data,
    isFetching: plansLoading,
    refetch: tmrAppealRefetch,
  } = useGetTmrAppealQuery({
    page,
    lang: i18n.language,
    filterParams: {
      status: selectedStatusId,
      class_groups: selectedClassTypeId,
      classes: selectedClassId,
      science: selectedScienceId,
      science_type: selectedScienceTypeId,
    },
  });
  const { data: classGroupData } = useGetClassGroupQuery(i18n.language);
  const { data: scienceData } = useGetScienceByClassQuery(
    { id: selectedClassTypeId, lang: i18n.language },
    { skip: selectedClassTypeId === 0 }
  );
  const { data: scienceTypesData } = useGetScienceTypeQuery({
    class_group: selectedClassTypeId,
    science: selectedScienceId,
    lang: i18n.language,
  });
  const { data: classData } = useGetAllClassQuery();

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleClassTypeChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedClassTypeId(event.target.value as number);
  };

  const handleScienceChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedScienceId(event.target.value as number);
  };

  const handleClassChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedClassId(event.target.value as number);
  };

  const handleScienceTypeChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedScienceTypeId(event.target.value as number);
  };

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedStatusId(event.target.value as number);
  };

  useEffect(() => {
    tmrAppealRefetch();
  }, []);

  return (
    <>
      {addStatus ? (
        <ApplicationAddForm setAddStatus={setAddStatus} />
      ) : (
        <ThemeFormWrap>
          <Common.Container>
            <ButtonsContainer>
              <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('settings.filter')}
              </Typography.h4>
              <Styles.Row gap={16} content={'space-between'}>
                <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} width='600px' gap={6}>
                  <StyledSelect
                    defaultValue={0}
                    value={selectedStatusId}
                    onChange={handleStatusChange}
                    name='planGrade'
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('settings.tmrTitle.status')}</StyledMenu>
                    {statusData?.map((item: any) => (
                      <StyledMenu value={item.key}>{t(item.value)}</StyledMenu>
                    ))}
                  </StyledSelect>

                  <StyledSelect
                    value={selectedClassTypeId as number}
                    onChange={handleClassTypeChange}
                    displayEmpty
                    name='gradeType'
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('auth.userReg.inputs.class_group')}</StyledMenu>
                    {classGroupData?.results &&
                      classGroupData?.results.map((classGroup: any, index: number) => (
                        <StyledMenu key={index} value={classGroup.id}>
                          {classGroup.name}
                        </StyledMenu>
                      ))}
                  </StyledSelect>

                  <StyledSelect
                    defaultValue={0}
                    disabled={selectedClassTypeId === 0}
                    onChange={handleScienceChange}
                    value={selectedScienceId as number}
                    name='planLesson'
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('auth.userReg.inputs.lesson')}</StyledMenu>
                    {scienceData?.results.map((item: any) => (
                      <StyledMenu value={item.id}>{item.name}</StyledMenu>
                    ))}
                  </StyledSelect>

                  <StyledSelect
                    defaultValue={0}
                    value={selectedClassId}
                    onChange={handleClassChange}
                    name='planGrade'
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('plans.main.table.select.grade')}</StyledMenu>
                    {classData?.results.map((item: any) => (
                      <StyledMenu value={item.id}>{item.name}</StyledMenu>
                    ))}
                  </StyledSelect>

                  <StyledSelect
                    disabled={selectedScienceId === 0}
                    value={selectedScienceTypeId}
                    onChange={handleScienceTypeChange}
                    displayEmpty
                    name='scienceType'
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('auth.userReg.inputs.grade')}</StyledMenu>
                    {scienceTypesData &&
                      scienceTypesData?.results.map((scienceInfo: any, index: number) => (
                        <StyledMenu key={index} value={scienceInfo.id}>
                          {scienceInfo.name} ({scienceInfo.count})
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                </Styles.Row>
                <PrimaryButton onClick={() => setAddStatus(true)} variant='contained'>
                  <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                  <Icons.Plus />
                </PrimaryButton>
              </Styles.Row>
            </ButtonsContainer>

            {plansLoading ? (
              <EmptyTitleWrapper content='center' align_items='center'>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            ) : (
              <>
                {data.results.length > 0 ? (
                  <Styles.Row>
                    <TableWrapper>
                      <StyledTableContainer>
                        <StyledTable stickyHeader aria-label='sticky table'>
                          <TableHead>
                            <TableRow>
                              {Settings.map(({ key, width, align }) => (
                                <TableCell align={align} key={key}>
                                  <GlobalStyles.TableHeaderTitle
                                    width={width}
                                    align_items='center'
                                    content={align === 'center' ? 'center' : 'flex-start'}
                                    wrap='nowrap'>
                                    {getHeaderTitle(key, t)}
                                  </GlobalStyles.TableHeaderTitle>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.results.map((row: TmrAppeal, index: number) => (
                              <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align='left'>
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell align='left'>{row?.class_groups}</TableCell>
                                <TableCell align='left'>{row?.classes}</TableCell>
                                <TableCell align='left'>{row?.science}</TableCell>
                                <TableCell align='center'>{row?.science_type}</TableCell>
                                <TableCell align='center'>
                                  {row?.status === 'accepted' ? (
                                    <StateChip
                                      label={t('settings.tmrTitle.table.accepted')}
                                      variant='outlined'
                                      color='primary'
                                    />
                                  ) : row?.status === 'rejected' ? (
                                    <EmptyChip
                                      label={t('settings.tmrTitle.table.rejected')}
                                      variant='outlined'
                                      color='primary'
                                    />
                                  ) : (
                                    <ScienceChip
                                      label={t('settings.tmrTitle.table.pending')}
                                      variant='outlined'
                                      color='primary'
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    </TableWrapper>
                    <Styles.Row content='flex-end'>
                      <Pagination
                        count={Math.ceil(data?.count / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color='primary'
                        shape='rounded'
                        style={{ marginTop: '20px' }}
                      />
                    </Styles.Row>
                  </Styles.Row>
                ) : (
                  <EmptyTitleWrapper content='center' align_items='center'>
                    <GlobalStyles.EmptyTypH4
                      color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('settings.applicationTitle.emptyTitle')}
                    </GlobalStyles.EmptyTypH4>
                  </EmptyTitleWrapper>
                )}
              </>
            )}
          </Common.Container>
        </ThemeFormWrap>
      )}
    </>
  );
};
