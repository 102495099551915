/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import Styles, { GlobalStyles, Typography } from '../../../../../../../../styles';
import Icons from '../../../../../../../../assets/icons';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  FirstRows,
  IconWrapper,
  LinksWrapper,
  LinkTypo,
  LinkTypoAndIcons,
  PrimaryButton,
  RatingFormWrap,
  SecondaryButton,
  SecondRows,
  StepsWrap,
} from './rating-form';
import colors from '../../../../../../../../constants';
import mock from '../../../../../../../../mock';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setModeratorStepTwo } from '../../../../../../../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Common } from '../../../../../../../../components';
import CommonList from '../../../../../../../../components/common';

interface IRatingFormProps {
  setPageState: (state: string) => void;
  pageState: string;
  setMainFile: (files: File[]) => void;
  mainFile: File[] | null;
  setRatingForm: (state: boolean) => void;
}

export const RatingForm: FC<IRatingFormProps> = ({
  setPageState,
  pageState,
  setMainFile,
  setRatingForm,
  mainFile,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  const [modalStatus, setModalStatus] = useState(false);
  const AuthorOptions = mock.Options;

  const formik = useFormik({
    initialValues: {
      selectedOption: localStorage.getItem('selectedOption') || '',
      file: mainFile || [],
    },
    validationSchema: Yup.object().shape({
      selectedOption: Yup.string().required('auth.authorReg.rating.errors.select'),
      file: Yup.array().min(1, 'auth.authorReg.rating.errors.file'),
    }),
    onSubmit: (values) => {
      setRatingForm(true);
      const formData = new FormData();
      formData.append('selectedOption', values.selectedOption);
      if (values.file) {
        setMainFile(values.file);

        values.file.forEach((file) => formData.append('files[]', file));
      }

      dispatch(setModeratorStepTwo({ selectedOption: values.selectedOption }));
      setPageState('contract');
    },
    validateOnChange: true,
  });

  useEffect(() => {
    localStorage.setItem('selectedOption', formik.values.selectedOption);
  }, [formik.values]);

  const animationVariants = {
    hidden: { opacity: 0, x: -30 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
      <RatingFormWrap>
        <Styles.Row gap={26}>
          <StepsWrap>
            <Styles.Row gap={24} wrap={'nowrap'}>
              {mock.Steps.registration.map((item, index) => (
                <motion.div
                  key={index}
                  initial='hidden'
                  animate='visible'
                  variants={animationVariants}
                  transition={{ duration: 0.5 * index }}>
                  <Styles.Row wrap={'nowrap'}>
                    <GlobalStyles.HoverableRow
                      gap={16}
                      wrap={'nowrap'}
                      onClick={() => setPageState(item.navigate)}>
                      <IconWrapper index={index} content={'center'} align_items={'center'}>
                        <item.icons
                          color={index === 1 ? colors.white : colors['typography-body']}
                        />
                      </IconWrapper>
                      <Styles.Row direction={'column'} width='123px'>
                        <Typography.h6 color='typography-body'>{t(item.label)}</Typography.h6>
                        <Typography.paragraphSmall color='typography-muted'>
                          {t(item.title)}
                        </Typography.paragraphSmall>
                      </Styles.Row>
                    </GlobalStyles.HoverableRow>
                    {item.chevron && (
                      <Styles.Row align_items={'center'}>
                        <Icons.ChevronRight />
                      </Styles.Row>
                    )}
                  </Styles.Row>
                </motion.div>
              ))}
            </Styles.Row>
          </StepsWrap>
          <Styles.Row gap={26}>
            <Styles.Row gap={6} direction={'column'}>
              <FirstRows content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.h3 color='typography-body'>
                  {t('auth.authorReg.rating.title')}
                </Typography.h3>
              </FirstRows>
            </Styles.Row>
            <Styles.Row gap={20}>
              <Styles.Row direction={'column'}>
                <FirstRows content={{ xs: 'center', md: 'flex-start' }}>
                  <Typography.paragraphSmallSemiBold style={{ display: 'contents' }}>
                    {t('auth.authorReg.rating.subTitle')}{' '}
                    <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                  </Typography.paragraphSmallSemiBold>
                </FirstRows>
                <FirstRows content={{ xs: 'center', md: 'flex-start' }}>
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    value={formik.values.selectedOption}
                    onChange={(e) => formik.setFieldValue('selectedOption', e.target.value)}
                    name='selectedOption'>
                    {AuthorOptions.map((option) => (
                      <GlobalStyles.HoverableFormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={
                          <Typography.paragraph color='typography-muted'>
                            {t(option.textKey)}
                          </Typography.paragraph>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FirstRows>
                {formik.touched.selectedOption && formik.errors.selectedOption && (
                  <Typography.paragraph color='danger-500'>
                    {t(formik.errors.selectedOption)}
                  </Typography.paragraph>
                )}
                <SecondRows content={'center'}>
                  <Common.Uploader
                    icon={<Icons.Upload color={colors['typography-body']} />}
                    type='file'
                    title='auth.authorReg.rating.uploader.title'
                    requiredText='auth.authorReg.rating.uploader.subtitle'
                    files={formik.values.file}
                    setFiles={(files) => {
                      formik.setFieldValue('file', [...formik.values.file, ...Array.from(files)]);
                    }}
                  />
                  {formik.touched.file && formik.errors.file ? (
                    <Typography.paragraph color='danger-500'>
                      {Array.isArray(formik.errors.file)
                        ? formik.errors.file.join(', ')
                        : t(formik.errors.file)}
                    </Typography.paragraph>
                  ) : null}
                </SecondRows>
              </Styles.Row>
            </Styles.Row>
            <SecondRows gap={16} content={'space-between'} wrap={'nowrap'}>
              <SecondaryButton variant='text' onClick={() => setPageState('account')}>
                <Icons.ArrowLeft color={colors['primary-500']} />
                <Typography.paragraph color='primary-500'>
                  {t('auth.authorReg.rating.cancelBtn')}
                </Typography.paragraph>
              </SecondaryButton>
              <PrimaryButton type='submit' variant='contained'>
                <Typography.paragraph color='white'>
                  {t('auth.authorReg.rating.nextBtn')}
                </Typography.paragraph>
                <Icons.ArrowRight />
              </PrimaryButton>
            </SecondRows>
            <SecondRows gap={6} content={{ xs: 'center', md: 'flex-start' }}>
              <Typography.paragraphMedium color='typography-muted'>
                {t('auth.authorReg.rating.ratingTitle')}
              </Typography.paragraphMedium>
              <LinkTypo color='primary-500' onClick={() => setModalStatus(true)}>
                {t('auth.authorReg.rating.ratingLink')}
              </LinkTypo>
            </SecondRows>
          </Styles.Row>
        </Styles.Row>
        <CommonList.Modal modalStatus={modalStatus} setModalStatus={setModalStatus} fullwidth>
          <Styles.Row content={'center'}>
            <Typography.h4 color='typography-body'>
              {t('auth.authorReg.rating.ratingLink')}
            </Typography.h4>
          </Styles.Row>
          <LinksWrapper content={'space-between'}>
            <a href={`tel:${+998781137580}`} target='_blank' rel='noopener noreferrer'>
              <LinkTypoAndIcons color='primary-500'>
                <Icons.PhoneCall color={colors['primary-500']} />
                {t('auth.authorReg.rating.phoneLink')}
              </LinkTypoAndIcons>
            </a>
            <a href='https://t.me/+998781136768' target='_blank' rel='noopener noreferrer'>
              <LinkTypoAndIcons color='primary-500'>
                <Icons.BrandTelegram color={colors['primary-500']} />
                {t('auth.authorReg.rating.tgLink')}
              </LinkTypoAndIcons>
            </a>
          </LinksWrapper>
        </CommonList.Modal>
      </RatingFormWrap>
    </form>
  );
};
