import styled from 'styled-components';
import { Typography } from '../../../styles';

export const StyledDiv = styled.div`
  display: inline-flex;
`;

export const StyledH6 = styled(Typography.h6)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;