/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TermsWrap } from './terms.s';
import Styles, { Typography } from '../../../styles';
import { Button, Typography as MuiTypo } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icons from '../../../assets/icons';
import colors from '../../../constants';

interface ITermsProps {}

export const Terms: FC<ITermsProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation('global');
  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get('returnTo') || '/role-selection';

  const handleBack = () => {
    navigate(returnTo);
  };

  return (
    <TermsWrap>
      <Styles.Row gap={24} align_content={'center'}>
        <Styles.Row gap={24}>
          <Styles.Row>
            <Typography.h3 color='primary-500'>{t('auth.terms.title')}</Typography.h3>
          </Styles.Row>
          <Styles.Row align_items={'center'}>
            <Typography.paragraph color='typography-muted'>
              {t('auth.terms.title')}
            </Typography.paragraph>
            {i18n.language === 'uz' ? (
              <a href='URL_ВАШЕГО_PDF_ФАЙЛА' target='_blank' rel='noopener noreferrer'>
                <Icons.FileText color={colors['typography-body']} />
              </a>
            ) : (
              <a href='URL_ВАШЕГО_PDF_ФАЙЛА' target='_blank' rel='noopener noreferrer'>
                <Icons.FileText color={colors['typography-body']} />
              </a>
            )}
          </Styles.Row>
        </Styles.Row>
        <Styles.Row
          content={'space-between'}
          wrap={'nowrap'}
          direction={{ xs: 'column', md: 'row' }}
          gap={24}>
          <Styles.Row gap={10} content={{ xs: 'space-between', md: 'flex-end' }}>
            <Button
              variant='text'
              onClick={handleBack}
              sx={{
                width: '130px',
                backgroundColor: '#3B82F629',
                textTransform: 'capitalize',
              }}>
              <MuiTypo
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#3B82F6',
                  textTransform: 'none',
                }}>
                {t('auth.terms.cancelBtn')}
              </MuiTypo>
            </Button>
          </Styles.Row>
        </Styles.Row>
      </Styles.Row>
    </TermsWrap>
  );
};
