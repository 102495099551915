export const Options = [
  { value: 'HIGHER', textKey: 'auth.authorReg.rating.select.1' },
  { value: 'EXCELLENT', textKey: 'auth.authorReg.rating.select.2' },
  { value: 'GOOD_TEACHER', textKey: 'auth.authorReg.rating.select.3' },
  { value: 'AUTHOR', textKey: 'auth.authorReg.rating.select.4' },
  { value: 'POPULAR', textKey: 'auth.authorReg.rating.select.5' },
];
export const Contract = [
  {
    value: '0',
    label: 'auth.authorReg.contract.select.yes',
  },
  {
    value: '1',
    label: 'auth.authorReg.contract.select.no',
  },
];
