/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../../styles';
import { ProfileContainer, NavbarAva, UserFullName, ImageWrapper } from './profile.s';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { imageRender } from '../../../../helpers/imageRender';

interface IProfileProps {
  theme: string;
}

export const Profile: FC<IProfileProps> = ({ theme }) => {
  const { t } = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState(null);
  const userState = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    navigate('/settings');
  };

  return (
    <ProfileContainer
      width={userState.last_name.length > 8 ? '250px' : '230px'}
      wrap={'nowrap'}
      onClick={handleClick}>
      <ImageWrapper>
        <NavbarAva
          src={
            userState.avatar == null || userState.avatar.length == 0
              ? '/images/navbar/placeholder.png'
              : imageRender(userState.avatar)
          }
          alt='avatar'
        />
      </ImageWrapper>
      <Styles.Row
        align_items={'flex-start'}
        direction={'column'}
        width='300px'
        style={{ overflow: 'hidden' }}>
        <UserFullName color={theme === 'dark' ? 'grey-300' : 'extra-dark'}>
          {userState.first_name.length > 11
            ? userState.first_name.slice(0, 11) + '...'
            : userState.first_name}{' '}
          {userState.last_name}
          {/* {fullNameGenerator(userState.first_name, userState.last_name)} */}
        </UserFullName>
        <UserFullName color={theme === 'dark' ? 'grey-300' : 'extra-dark'}>
          {userState.father_name ? userState.father_name : ''}
        </UserFullName>
        <Typography.placeholderSm color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
          {userState.role}
        </Typography.placeholderSm>
      </Styles.Row>
    </ProfileContainer>
  );
};
