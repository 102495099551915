import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const BrandTelegram: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors['extra-white'],
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.5 10L11.5 14L17.5 20L21.5 4L3.5 11L7.5 13L9.5 19L12.5 15'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.5 10L11.5 14L17.5 20L21.5 4L3.5 11L7.5 13L9.5 19L12.5 15'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
