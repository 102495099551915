/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import {
  FirstFormWrap,
  PrimaryButton,
  PrimaryIconBtn,
  SecondaryButton,
  SecondaryIconBtn,
  StyledColumn,
  StyledTextField,
} from './first-form.s';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../../assets/icons';
import colors from '../../../../../constants';
import { useAddTopicMutation } from '../../../../../store/services/moderatorPlansApi';

interface IFirstFormProps {
  theme: string;
}

interface IFormData {
  topics: {
    name: string;
    description: string;
    hours: string;
    weeks: string;
  }[];
}

export const FirstForm: FC<IFirstFormProps> = ({ theme }) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const number = parseInt(parts[parts.length - 2]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addTopic, { isLoading }] = useAddTopicMutation();
  const initialValues: IFormData = {
    topics: [{ name: '', description: '', hours: '', weeks: '' }],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      topics: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('plans.viewAddItem.body.errors.global'),
          description: Yup.string().required('plans.viewAddItem.body.errors.global'),
          hours: Yup.string().required('plans.viewAddItem.body.errors.global'),
          weeks: Yup.string().required('plans.viewAddItem.body.errors.global'),
        })
      ),
    }),
    onSubmit: async (values) => {
      try {
        const topics = values.topics.map((topic, index) => ({
          name: topic.name,
          description: topic.description,
          hours: topic.hours,
          weeks: topic.weeks,
          plan_id: number,
        }));

        const jsonData = JSON.stringify(topics);
        await addTopic(jsonData).unwrap();

        setSnackbarMessage('mainPage.snackbar.sampleAdded');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate(`/science-topics/${number}/`);
        }, 2000);
      } catch (error: any) {
        if(error.data.error === 'You are not allowed to create topic'){
          setSnackbarMessage('mainPage.snackbar.topicErr');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage('mainPage.snackbar.sampleErr');
          setSnackbarOpen(true);
        }
        
      }
    },
    validateOnChange: true,
  });

  const getError = (index: number, field: keyof IFormData['topics'][0]) => {
    const errors = formik.errors.topics?.[index];
    return errors && typeof errors === 'object' && !Array.isArray(errors) ? errors[field] : '';
  };

  const getIconColor = (isDisabled: boolean, theme: string) => {
    if (isDisabled) {
      return theme === 'dark' ? colors['dark-theme-secondary'] : colors['grey-300']; // Цвет иконки для отключенной кнопки
    }
    return theme === 'dark' ? colors['grey-300'] : colors['typography-body'];
  };

  return (
    <FirstFormWrap onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <FieldArray
          name='topics'
          render={({ push, remove }) => (
            <>
              {formik.values.topics.map((topic, index) => (
                <Styles.Row key={index} wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
                  <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
                    <Styles.Row gap={4}>
                      <StyledColumn size={6} difference={0} gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                            as={'label'}
                            subColor='danger-600'
                            htmlFor={`topics.${index}.name`}>
                            {t('plans.viewAddItem.body.topicAdd.input.name')}
                            <span>*</span>
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row
                          content={{ xs: 'center', sm: 'flex-start' }}
                          direction={'column'}>
                          <StyledTextField
                            placeholder='...'
                            name={`topics.${index}.name`}
                            value={formik.values.topics[index].name}
                            onChange={formik.handleChange}
                          />

                          {formik.touched.topics?.[index]?.name && (
                            <Typography.paragraph color='danger-500'>
                              {t(getError(index, 'name') || '')}
                            </Typography.paragraph>
                          )}
                        </Styles.Row>
                      </StyledColumn>

                      <StyledColumn size={6} difference={0} gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            subColor='danger-600'
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('plans.viewAddItem.body.topicAdd.input.duration')}
                            <span>*</span>
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledTextField
                            placeholder='...'
                            type='number'
                            name={`topics.${index}.hours`}
                            value={formik.values.topics[index].hours}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.topics?.[index]?.hours && (
                            <Typography.paragraph color='danger-500'>
                              {t(getError(index, 'hours') || '')}
                            </Typography.paragraph>
                          )}
                        </Styles.Row>
                      </StyledColumn>
                    </Styles.Row>

                    <Styles.Row gap={4}>
                      <StyledColumn size={6} difference={0} gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            subColor='danger-600'
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('plans.viewAddItem.body.topicAdd.input.description')}
                            <span>*</span>
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledTextField
                            placeholder='...'
                            name={`topics.${index}.description`}
                            value={formik.values.topics[index].description}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.topics?.[index]?.description && (
                            <Typography.paragraph color='danger-500'>
                              {t(getError(index, 'description') || '')}
                            </Typography.paragraph>
                          )}
                        </Styles.Row>
                      </StyledColumn>

                      <StyledColumn size={6} difference={0} gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            subColor='danger-600'
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('plans.viewAddItem.body.topicAdd.input.week')}
                            <span>*</span>
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledTextField
                            placeholder='...'
                            type='number'
                            name={`topics.${index}.weeks`}
                            value={formik.values.topics[index].weeks}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.topics?.[index]?.weeks && (
                            <Typography.paragraph color='danger-500'>
                              {t(getError(index, 'weeks') || '')}
                            </Typography.paragraph>
                          )}
                        </Styles.Row>
                      </StyledColumn>
                    </Styles.Row>
                  </Styles.Row>

                  <Styles.Row
                    width='auto'
                    wrap={'nowrap'}
                    content={'flex-end'}
                    gap={16}
                    align_items={'center'}>
                    <SecondaryIconBtn
                      type='button'
                      variant='outlined'
                      onClick={() => {
                        if (formik.values.topics.length > 1) {
                          remove(index);
                        }
                      }}
                      disabled={formik.values.topics.length <= 1}>
                      <Icons.Trash color={getIconColor(formik.values.topics.length <= 1, theme)} />
                    </SecondaryIconBtn>
                    <PrimaryIconBtn
                      type='button'
                      variant='contained'
                      onClick={() => push({ name: '', description: '', hours: '' })}>
                      <Icons.Plus />
                    </PrimaryIconBtn>
                  </Styles.Row>
                </Styles.Row>
              ))}
            </>
          )}
        />

        <Styles.Row gap={16} content={'space-between'} wrap={'nowrap'}>
          <SecondaryButton
            variant='contained'
            onClick={() => navigate(`/science-topics/${number}/`)}
            disabled={isLoading}>
            <Typography.h6 color='secondary-500'>
              {t('plans.viewAddItem.body.button.previous')}
            </Typography.h6>
          </SecondaryButton>

          <PrimaryButton type='submit' variant='contained' disabled={isLoading}>
            <Typography.h6 color='white'>{t('plans.viewAddItem.body.button.next')}</Typography.h6>
          </PrimaryButton>
        </Styles.Row>
      </FormikProvider>

      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </FirstFormWrap>
  );
};
