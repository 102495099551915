/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import Styles, { Typography } from '../../../styles';
import { StyledDiv, StyledH6 } from './manual.s';
import colors from '../../../constants';
import { useTranslation } from 'react-i18next';
import Icons from '../../../assets/icons';

interface IManualProps {
}

export const Manual: FC<IManualProps> = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [t, i18n] = useTranslation('global');

  return (
    <Styles.Row>
      {i18n.language === 'ru' && (
        <StyledH6 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
          {t('settings.manual')}
          <StyledDiv>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={'https://classcom.felixits.uz/static/Classcom.pdf'}>
              <Icons.FileText
                width={28}
                height={28}
                viewBox='0 0 25 18'
                color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
              />
            </a>
          </StyledDiv>
        </StyledH6>
      )}
    </Styles.Row>
  );
};
