/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  Bar,
  FifthRows,
  FirstRows,
  FormWrap,
  FourthRows,
  PrimaryButton,
  SecondaryButton,
  SecondRows,
  StyledMenu,
  StyledParagraph,
  StyledPhoneFormat,
  StyledSelect,
  StyledTextField,
  StyledTypographyParagraph,
  ThirdRows,
  UserRegisterFormWrap,
} from './userRegister-form.s';
import Styles, { Typography } from '../../../../../../styles';
import { Checkbox, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../../../assets/icons';
import colors from '../../../../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useGetDistrictQuery,
  useGetRegionQuery,
  useGetSchoolTypeQuery,
  useUserRegisterMutation,
} from '../../../../../../store/services/authApi';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../../../store/slices/authSlice';
import {
  useGetScienceByClassQuery,
  useGetScienceTypeQuery,
} from '../../../../../../store/services/scienceApi';
import { useTranslation } from 'react-i18next';
import { truncateString } from '../../../../../../helpers/truncateString';
import { useMultiSelectControl, useWindowWidth } from '../../../../../../hooks';
import { useGetClassGroupQuery } from '../../../../../../store/services/classApi';

interface IUserRegisterFormProps {}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'auth.userReg.errors.minName')
    .required('auth.userReg.errors.required'),
  lastName: Yup.string()
    .min(2, 'auth.userReg.errors.minName')
    .required('auth.userReg.errors.required'),
  province: Yup.number().required('auth.userReg.errors.required'),
  city: Yup.string().required('auth.userReg.errors.required'),
  institutionType: Yup.string().required('auth.userReg.errors.required'),
  institutionNumber: Yup.string().required('auth.userReg.errors.required'),
  classes: Yup.array()
    .min(1, 'auth.userReg.errors.required')
    .required('auth.userReg.errors.required'),
  subject: Yup.number().required('auth.userReg.errors.required'),
  class_group: Yup.number().required('auth.userReg.errors.required'),
  school: Yup.number().required('auth.userReg.errors.required'),
  phoneNumber: Yup.string()
    .min(9, 'auth.userReg.errors.minNumber')
    .required('auth.userReg.errors.required'),
  password: Yup.string()
    .min(8, 'auth.userReg.errors.minPass')
    .matches(/[a-zA-Zа-яА-я]/, 'auth.userReg.errors.matches')
    .required('auth.userReg.errors.required'),
});

export const UserRegisterForm: FC<IUserRegisterFormProps> = (props) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('global');
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const maxLength = windowWidth <= 992 ? 30 : windowWidth > 992 && windowWidth <= 1250 ? 20 : 30;
  const getRegion = useGetRegionQuery(i18n.language);
  const [district, setDistrict] = useState(1);
  const [classGrp, setClassGrp] = useState(0);
  const [scienceGrp, setScienceGrp] = useState(0);
  const getDistrict = useGetDistrictQuery({ region_id: district, lang: i18n.language });
  const [showPassword, setShowPassword] = useState(true);
  const [userRegister, { isLoading }] = useUserRegisterMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { data: scienceData } = useGetScienceByClassQuery({
    id: classGrp || Number(localStorage.getItem('class_group')),
    lang: i18n.language,
  });
  const { data: classGroupData } = useGetClassGroupQuery(i18n.language);
  const { data: scienceTypesData } = useGetScienceTypeQuery({
    class_group: classGrp || Number(localStorage.getItem('class_group')),
    science: scienceGrp || Number(localStorage.getItem('science')),
    lang: i18n.language,
  });
  const [eduType, setEduType] = useState("O'rta maxsus");
  const { data: schoolType } = useGetSchoolTypeQuery({
    educationType: eduType,
    lang: i18n.language,
  });
  const [scienceInfo, setScienceInfo] = useState(Number(localStorage.getItem('science')) || 2);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      fatherName: localStorage.getItem('fatherName') || '',
      firstName: localStorage.getItem('firstName') || '',
      lastName: localStorage.getItem('lastName') || '',
      province: Number(localStorage.getItem('province')) || 1,
      city: localStorage.getItem('city') || '5',
      institutionType: localStorage.getItem('institutionType') || "O'rta maxsus",
      class_group: Number(localStorage.getItem('class_group')) || 0,
      institutionNumber: localStorage.getItem('institutionNumber') || '',
      subject: Number(localStorage.getItem('science')) || 0,
      school: Number(localStorage.getItem('school')) || 0,
      classes: JSON.parse(localStorage.getItem('science_types') || '[]'),
      phoneNumber: localStorage.getItem('phoneNumber') || '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('father_name', values.fatherName);
        formData.append('first_name', values.firstName);
        formData.append('last_name', values.lastName);
        formData.append('phone', values.phoneNumber);
        formData.append('password', values.password);
        formData.append('role', 'user');
        formData.append('class_group', values.class_group.toString());
        formData.append('region', values.province.toString());
        formData.append('district', values.city.toString());
        formData.append('institution_number', values.institutionNumber);
        formData.append('science', values.subject.toString());
        values.classes.forEach((item: string, index: number) => {
          formData.append(`science_types[${index}]`, item);
        });
        await userRegister({ formData, lang: i18n.language })
          .unwrap()
          .then((payload) => {
            dispatch(
              setUser({
                father_name: values.fatherName,
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phoneNumber,
                password: values.password,
                region: values.province,
                class_group: values.class_group,
                district: Number(values.city),
                institution: values.institutionType,
                institution_number: values.institutionNumber,
                science_types: values.classes,
              })
            );
            navigate('/author/user-verification');
          })
          .catch((error) => {
            if (error.data.phone) {
              setSnackbarMessage(error.data.phone);
            } else {
              setSnackbarMessage(error.data.message);
            }
            setSnackbarOpen(true);
          });
      } catch (error: any) {}
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getRegion;
        await getDistrict.refetch();
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchData();
  }, [getRegion]);

  useEffect(() => {
    setDistrict(formik.values.province);
  }, [formik.values.province]);

  useEffect(() => {
    if (getDistrict.data) {
      formik.setFieldValue('city', getDistrict.data.districts[0].id);
    }
  }, [getDistrict.data]);

  useEffect(() => {
    localStorage.setItem('fatherName', formik.values.fatherName);
    localStorage.setItem('firstName', formik.values.firstName);
    localStorage.setItem('lastName', formik.values.lastName);
    localStorage.setItem('province', formik.values.province.toString());
    localStorage.setItem('city', formik.values.city.toString());
    localStorage.setItem('class_group', formik.values.class_group.toString());
    localStorage.setItem('institutionType', formik.values.institutionType);
    localStorage.setItem('institutionNumber', formik.values.institutionNumber);
    localStorage.setItem('science', formik.values.subject.toString());
    localStorage.setItem('school', formik.values.school.toString());
    localStorage.setItem('science_types', JSON.stringify(formik.values.classes));
    localStorage.setItem('phoneNumber', formik.values.phoneNumber);
  }, [formik.values, scienceInfo]);

  useEffect(() => {
    localStorage.setItem('science_types', JSON.stringify([]));
  }, [scienceInfo]);

  const { openSelectId, open, close } = useMultiSelectControl();

  return (
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
      <UserRegisterFormWrap>
        <FormWrap>
          <Styles.Row gap={6} content={{ xs: 'center', md: 'flex-start' }}>
            <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
              <Typography.h3 color='typography-body'>{t('auth.userReg.title')} 🚀</Typography.h3>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
              <StyledTypographyParagraph color='typography-body'>
                {t('auth.userReg.subTitle')}
              </StyledTypographyParagraph>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row gap={16}>
            <FirstRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.name')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder='John'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName && Boolean(formik.errors.firstName)
                    }></StyledTextField>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.firstName)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.surname')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder='Doe'
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  />
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.lastName)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
            </FirstRows>
            <SecondRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.fatherName')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder='David'
                    name='fatherName'
                    value={formik.values.fatherName}
                    onChange={formik.handleChange}
                    error={formik.touched.fatherName && Boolean(formik.errors.fatherName)}
                  />
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.province')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledSelect
                    value={formik.values.province}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue('province', e.target.value);
                      getDistrict.refetch();
                    }}
                    open={openSelectId === 'province'}
                    onOpen={() => open('province')}
                    onClose={close}
                    displayEmpty
                    name='province'
                    error={formik.touched.province && Boolean(formik.errors.province)}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '500px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {getRegion?.data?.results &&
                      getRegion?.data?.results.map((region: any, index: number) => (
                        <StyledMenu key={index} value={region.id}>
                          {truncateString(region.region, maxLength)}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.touched.province && formik.errors.province && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.province)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
            </SecondRows>
            <ThirdRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.city')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledSelect
                    value={formik.values.city}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue('city', e.target.value);
                    }}
                    displayEmpty
                    name='city'
                    open={openSelectId === 'city'}
                    onOpen={() => open('city')}
                    onClose={close}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '500px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {getDistrict.data &&
                      getDistrict.data.districts &&
                      getDistrict.data.districts.map((district: any, index: number) => (
                        <StyledMenu key={index} value={district.id}>
                          {district.district}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.touched.city && formik.errors.city && (
                    <Typography.paragraphSmall color='danger-600'>
                      {/* {formik.errors.city} */}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.institutionName')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder={t('auth.userReg.inputs.institutionNamePlaceholder')}
                    name='institutionNumber'
                    value={formik.values.institutionNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.institutionNumber && Boolean(formik.errors.institutionNumber)
                    }
                  />
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  {formik.touched.institutionNumber && formik.errors.institutionNumber && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.institutionNumber)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
            </ThirdRows>
            <FourthRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.class_group')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledSelect
                    value={formik.values.class_group}
                    onChange={(event) => {
                      formik.handleChange(event);
                      setClassGrp(event.target.value as number);
                    }}
                    displayEmpty
                    open={openSelectId === 'class_group'}
                    onOpen={() => open('class_group')}
                    onClose={close}
                    renderValue={(selected: any) => {
                      if (selected === 0) {
                        return <>{t('auth.userReg.inputs.placeholder')}</>;
                      }

                      const selectedClassGroup = classGroupData?.results.find(
                        (group: any) => group.id === selected
                      );
                      return selectedClassGroup ? selectedClassGroup.name : '';
                    }}
                    name='class_group'
                    error={formik.touched.class_group && Boolean(formik.errors.class_group)}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '500px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {classGroupData?.results &&
                      classGroupData?.results.map((classGroup: any, index: number) => (
                        <StyledMenu key={index} value={classGroup.id}>
                          {classGroup.name}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.touched.class_group && formik.errors.class_group && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.class_group)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.lesson')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledSelect
                    disabled={formik.values.class_group === 0}
                    value={formik.values.subject}
                    onChange={(event) => {
                      formik.handleChange(event);
                      setScienceGrp(event.target.value as number);
                    }}
                    open={openSelectId === 'subject'}
                    onOpen={() => open('subject')}
                    onClose={close}
                    renderValue={(selected: any) => {
                      if (formik.values.class_group === 0) {
                        return <>{t('auth.userReg.inputs.sciencePlaceholder')}</>;
                      }

                      const findScienceName = (id: number) => {
                        const science = scienceData?.results.find((item: any) => item.id === id);
                        return science ? science.name : '';
                      };

                      return selected ? (
                        findScienceName(selected)
                      ) : (
                        <>{t('auth.userReg.inputs.placeholder')}</>
                      );
                    }}
                    displayEmpty
                    name='subject'
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '500px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {scienceData?.results &&
                      scienceData?.results.map((science: any, index: number) => (
                        <StyledMenu
                          key={index}
                          value={science.id}
                          onClick={() => {
                            setScienceInfo(science.id);
                            formik.setFieldValue('classes', []);
                          }}>
                          {science.name}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.touched.subject && formik.errors.subject && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.subject)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
            </FourthRows>
            <FifthRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.grade')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledSelect
                    disabled={formik.values.subject === 0}
                    value={formik.values.classes}
                    onChange={formik.handleChange}
                    open={openSelectId === 'classes'}
                    onOpen={() => open('classes')}
                    onClose={close}
                    renderValue={(selected: any) => {
                      if (formik.values.subject === 0) {
                        return <>{t('auth.userReg.inputs.scienceGrpPlaceholder')}</>;
                      } else {
                        const getSelectedNames = () => {
                          return selected
                            .map((id: number) => {
                              const science = scienceTypesData?.results.find(
                                (item: any) => item.id === id
                              );
                              return science ? science.name : '';
                            })
                            .filter((name: string) => name !== '')
                            .join(', ');
                        };
                        return selected.length === 0 ? (
                          <>{t('auth.userReg.inputs.placeholder')}</>
                        ) : (
                          getSelectedNames()
                        );
                      }
                    }}
                    displayEmpty
                    name='classes'
                    multiple
                    error={formik.touched.classes && Boolean(formik.errors.classes)}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '500px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    {scienceTypesData &&
                      scienceTypesData?.results.map((scienceInfo: any, index: number) => (
                        <StyledMenu key={index} value={scienceInfo.id}>
                          {scienceInfo.name}
                        </StyledMenu>
                      ))}
                  </StyledSelect>
                  {formik.touched.classes && formik.errors.classes && (
                    <Typography.paragraphSmall color='danger-600'>
                      {/* {t(formik.errors.classes)} */}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.phone')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledPhoneFormat
                    id='login phone number'
                    name='phoneNumber'
                    type='text'
                    format='+998 (##) ###-##-##'
                    allowEmptyFormatting
                    mask='_'
                    value={formik.values.phoneNumber}
                    onValueChange={(values) =>
                      formik.setValues({
                        ...formik.values,
                        phoneNumber: values.value,
                      })
                    }
                    errorColor={
                      Boolean(formik.errors.phoneNumber) && Boolean(formik.touched.phoneNumber)
                    }
                  />
                  <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <Typography.paragraphSmall color='danger-600'>
                        {t(formik.errors.phoneNumber)}
                      </Typography.paragraphSmall>
                    )}
                  </Styles.Row>
                </Styles.Row>
              </Styles.Column>
            </FifthRows>
            <FifthRows gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
              <Styles.Column size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.passStart')}
                  </Typography.paragraphSmall>
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('auth.userReg.inputs.passPlaceholder')}
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={() => setShowPassword(true)}
                    onBlur={() => setShowPassword(false)}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()} // prevent focus change
                          >
                            {showPassword ? (
                              <Icons.Eye color={colors['typography-muted']} />
                            ) : (
                              <Icons.EyeOff color={colors['typography-muted']} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('auth.userReg.inputs.passEnd')}
                  </Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  {formik.touched.password && formik.errors.password && (
                    <Typography.paragraphSmall color='danger-600'>
                      {t(formik.errors.password)}
                    </Typography.paragraphSmall>
                  )}
                </Styles.Row>
              </Styles.Column>
            </FifthRows>
            {/* fix Link to */}
            <Styles.Row gap={6} align_items={'center'} content={{ xs: 'center', sm: 'flex-start' }}>
              <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                  i18n.language === 'uz'
                    ? 'https://classcom.felixits.uz/static/doc/document_uz.pdf'
                    : 'https://classcom.felixits.uz/static/doc/document_ru.pdf'
                }>
                <Typography.paragraph color='primary-500' style={{ textDecoration: 'underline' }}>
                  {t('auth.userReg.contractLink')}
                </Typography.paragraph>
              </a>
              <StyledParagraph color='typography-body'>
                {t('auth.userReg.contractTitle')}
              </StyledParagraph>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row wrap={'nowrap'} gap={16} direction={'row'} content={'space-between'}>
            <SecondaryButton variant='text' onClick={() => navigate('/author/select-user')}>
              <Typography.paragraph color='primary-500'>
                {t('auth.userReg.cancelBtn')}
              </Typography.paragraph>
            </SecondaryButton>
            <PrimaryButton disabled={!isChecked || isLoading} type='submit' variant='contained'>
              {isLoading ? (
                <Icons.Loader />
              ) : (
                <Typography.paragraph color='white'>
                  {t('auth.userReg.nextBtn')}
                </Typography.paragraph>
              )}
            </PrimaryButton>
          </Styles.Row>
        </FormWrap>
        <Bar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      </UserRegisterFormWrap>
    </form>
  );
};
