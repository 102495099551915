import { FC, ReactNode, useEffect, useState } from 'react';
import { Modal as UserModal, Fade } from '@mui/material';
import { ChildrenWrap, IconWrap, StyledModalBox } from './modal.s';
import Icons from '../../../assets/icons';
import colors from '../../../constants';

interface IModalProps {
  fullwidth?: boolean;
  children: ReactNode;
  modalStatus: boolean;
  setModalStatus: (status: boolean) => void;
}

export const Modal: FC<IModalProps> = ({ children, setModalStatus, modalStatus, fullwidth }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const handleStorageChange = () => {
      setTheme(localStorage.getItem('theme') || 'light');
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (modalStatus) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalStatus]);

  return (
    <UserModal
      open={modalStatus}
      onClose={() => setModalStatus(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableScrollLock={true}
      closeAfterTransition
    >
      <Fade in={modalStatus} timeout={300}>
        <StyledModalBox theme={theme} modalWidth={fullwidth}>
          <IconWrap theme={theme} align_content={'center'} onClick={() => setModalStatus(false)}>
            <Icons.X  color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}/>
          </IconWrap>
          <ChildrenWrap modalWidth={fullwidth}>
            {children}
          </ChildrenWrap>
        </StyledModalBox>
      </Fade>
    </UserModal>
  );
};
