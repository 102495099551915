/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import { Common } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { Pagination, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useGetPlanByIdQuery } from '../../../../store/services/plansApi';
import { useGetTopicByParamsQuery, useGetTopicByPlanIdQuery } from '../../../../store/services/topicApi';
import {
  PlansWrap,
  StyledTable,
  TableWrapper,
  ButtonsContainer,
  StyledTableContainer,
  EmptyTitleWrapper,
  StyledTableCell,
} from './subject-view.s';
import { useAppTheme } from '../../../../hooks';

export const SubjectView = () => {
  const { theme } = useAppTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [t, i18n] = useTranslation('global');
  const url = window.location.href;
  const parts = url.split('/');
  const number = parseInt(parts[parts.length - 2]);
  const {
    data: topicData,
    isFetching: topicLoading,
    refetch,
  } = useGetTopicByPlanIdQuery({
    plan_id: number,
    lang: i18n.language,
    page: page,
  });

  const { data: planData, isFetching } = useGetPlanByIdQuery({ id: number, lang: i18n.language });

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    sessionStorage.setItem('previousUrl', location.pathname);
  }, [location]);

  return (
    <PlansWrap>
      {isFetching || topicLoading ? (
        <Common.Container>
          <EmptyTitleWrapper content={'center'} align_items={'center'}>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.loadingTitle')}
            </Typography.h4>
          </EmptyTitleWrapper>
        </Common.Container>
      ) : (
        <Styles.Row>
          <Styles.Row gap={16}>
            <Styles.Row gap={6}>
              <Typography.h4 color='typography-muted'>{t('plans.main.title')} /</Typography.h4>
              <Typography.h4 color='typography-body'>{planData?.results[0]?.science_types?.name}</Typography.h4>
            </Styles.Row>
            <Styles.Row>
              <Styles.Row gap={8} style={{ marginBottom: '20px' }}>
                <Styles.Row>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewAddItem.body.input.gradeType')}: {planData?.results[0]?.class_group?.name}
                  </Typography.paragraph>
                </Styles.Row>
                <Styles.Row>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.view.table.select.grade')}: {planData?.results[0]?.classes?.name}
                  </Typography.paragraph>
                </Styles.Row>
                <Styles.Row>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.view.table.select.quater')}:  {planData?.results[0]?.quarter?.choices}
                  </Typography.paragraph>
                </Styles.Row>
              </Styles.Row>
              {topicData?.results.length === 0 ? (
                <Common.Container>
                  <EmptyTitleWrapper
                    gap={4}
                    content={'center'}
                    align_items={'center'}
                    direction={'column'}>
                    <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('plans.viewItem.body.title.topicEmpty')}
                    </Typography.h4>
                  </EmptyTitleWrapper>
                </Common.Container>
              ) : (
                <Common.Container>
                  <ButtonsContainer>
                    <Styles.Row
                      gap={16}
                      wrap={{ xs: 'wrap', md: 'nowrap' }}
                      content={'space-between'}>
                      <Styles.Row>
                        <Typography.h4 color='typography-muted'>
                          {t('plans.view.table.header')}{' '}
                        </Typography.h4>
                      </Styles.Row>
                    </Styles.Row>
                  </ButtonsContainer>
                  <Styles.Row>
                    <TableWrapper>
                      <StyledTableContainer>
                        <StyledTable stickyHeader aria-label='sticky table'>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  content={'center'}
                                  align_items={'center'}>
                                  {t('plans.view.table.title.number')}
                                </GlobalStyles.TableHeaderTitle>
                              </StyledTableCell>
                              <StyledTableCell align='center' sx={{ width: '600px' }}>
                                <GlobalStyles.TableHeaderTitle
                                  content={'center'}
                                  align_items={'center'}>
                                  {t('plans.view.table.title.name')}
                                </GlobalStyles.TableHeaderTitle>
                              </StyledTableCell>
                              <StyledTableCell align='center' sx={{ width: '600px' }}>
                                <GlobalStyles.TableHeaderTitle
                                  content={'center'}
                                  align_items={'center'}>
                                  {t('plans.view.table.title.week')}
                                </GlobalStyles.TableHeaderTitle>
                              </StyledTableCell>
                              <StyledTableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  content={'center'}
                                  align_items={'center'}>
                                  {t('plans.view.table.title.duration')}
                                </GlobalStyles.TableHeaderTitle>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topicData?.results.map((item: any) => (
                              <TableRow
                                key={item?.id}
                                onClick={() => navigate(`/plan-view/${item?.id}`)}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  cursor: 'pointer',
                                }}>
                                <TableCell align='center'>{item.sequence_number}</TableCell>
                                <TableCell align='left'>{item.name}</TableCell>
                                <TableCell align='center'>{item.weeks}</TableCell>
                                <TableCell align='center'>{item.hours}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    </TableWrapper>
                    <Styles.Row content={'flex-end'}>
                        <Pagination
                          count={Math.ceil((topicData?.count ?? 0) / rowsPerPage) || 1}
                          page={page}
                          onChange={handleChangePage}
                          color='primary'
                          shape='rounded'
                          style={{ marginTop: '20px' }}
                        />
                      </Styles.Row>
                  </Styles.Row>
                </Common.Container>
              )}
            </Styles.Row>
          </Styles.Row>
        </Styles.Row>
      )}
    </PlansWrap>
  );
};
