import { TopicPaginationType } from '../../types/topic';
import { AI, TOPIC } from '../URLs';
import { api } from './api';

export const topicsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTopic: builder.query<any, void>({
      query: () => ({
        url: TOPIC,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: ['getAllTopic'],
    }),
    getTopicByParams: builder.query<any, { classNum: number; science: number; quarter: number }>({
      query: ({ classNum, science, quarter }) => ({
        url: `${TOPIC}?_class=${classNum}&science=${science}&quarter=${quarter}`,
        method: 'GET',
      }),
      providesTags: ['getTopicByParams'],
    }),
    getTopicCalculationByParams: builder.query<
      any,
      {
        science: number | null;
        classes: number | null;
        week: number | null;
        schedule: number | null;
      }
    >({
      query: ({ science, classes, week, schedule }) => ({
        url: `algorithm/?science=${science}&classes=${classes}&week=${week}&schedule_id=${schedule}`,
        method: 'GET',
      }),
      providesTags: ['getTopicCalculationByParams'],
    }),
    aiQuestionAndAnswer: builder.mutation<any, string>({
      query: (body) => {
        return {
          url: AI,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: ['aiQuestionAndAnswer'],
    }),
    getTopicByPlanId: builder.query<
      TopicPaginationType,
      { plan_id: number; page?: number; page_size?: number | null; lang: string }
    >({
      query: ({
        plan_id,
        page_size = 10,
        page = 1,
        lang,
      }: {
        plan_id: number;
        page?: number;
        page_size?: number;
        lang: string;
      }) => ({
        url: TOPIC,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
        params: {
          plan_id,
          page_size,
          page,
        },
      }),
      providesTags: ['getTopicPlanId'],
    }),
  }),
});

export const {
  useGetAllTopicQuery,
  useGetTopicByParamsQuery,
  useGetTopicCalculationByParamsQuery,
  useAiQuestionAndAnswerMutation,
  useGetTopicByPlanIdQuery
} = topicsApi;
