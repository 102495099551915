import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { api } from '../services/api';
import { authApi } from '../services/authApi';
import { AuthSate } from '../../interfaces/auth-interface';
import { Roles } from '../../types/roles';
import { getToken } from '../../helpers/token';

// const isUser = getToken() ? true : false;
const initialState: AuthSate = {
  access_token: getToken() ? getToken() : '',
  first_name: '',
  refresh_token: '',
  fullname: '',
  role: getToken() ? Roles.USER : Roles.AUTH,
  phone: '',
  reset_password_token: '',
  last_name: '',
  avatar: '',
  waiting_access_token: '',
  waiting_refresh_token: '',
  plan_creatable: false,
  resource_creatable: false,
  topic_creatable: false,
};

export const authSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthSate>) => {
      state.id = action.payload.id;
      state.father_name = action.payload.father_name;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.phone = action.payload.phone;
      state.avatar = action.payload.avatar;
      state.region = action.payload.region;
      state.district = action.payload.district;
      state.institution = action.payload.institution;
      state.institution_number = action.payload.institution_number;
      state.science_types = action.payload.science_types;
      state.role = action.payload.role;
      state.class_group = action.payload.class_group;
      state.plan_creatable = action.payload.plan_creatable;
      state.resource_creatable = action.payload.resource_creatable;
      state.topic_creatable = action.payload.topic_creatable;
    },
    setModeratorStepOne: (state, action: PayloadAction<AuthSate>) => {
      localStorage.setItem('moderator-1', JSON.stringify(action.payload));

      state.father_name = action.payload.father_name;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.region = action.payload.region;
      state.district = action.payload.district;
      state.institution = action.payload.institution;
      state.institution_number = action.payload.institution_number;
      state.science = action.payload.science;
      state.school = action.payload.school;
      state.science_types = action.payload.science_types;
      state.phone = action.payload.phone;
      state.password = action.payload.password;
      state.class_group = action.payload.class_group;
    },
    setModeratorStepTwo: (state, action: PayloadAction<{ selectedOption: string }>) => {
      localStorage.setItem('moderator-2', JSON.stringify(action.payload));

      state.degree = action.payload.selectedOption;
    },
    setRole: (state, action: PayloadAction<AuthSate>) => {
      state.role = action.payload.role;
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    setPhoneNumber: (state, action: PayloadAction<AuthSate>) => {
      state.phone = action.payload.phone;
    },
    setWaiting: (state, action: PayloadAction<AuthSate>) => {
      state.waiting_access_token = action.payload.access_token;
      state.waiting_refresh_token = action.payload.refresh_token;
    },
    setResetPasswordToken: (state, action: PayloadAction<AuthSate>) => {
      state.reset_password_token = action.payload.reset_password_token;
    },
    setLogout: (state) => {
      localStorage.removeItem('theme');
      localStorage.removeItem('formStatus');
      localStorage.removeItem('fontSize');
      localStorage.removeItem('waiting_accessToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('messages');

      api.util.resetApiState();
      authApi.util.resetApiState();

      state.access_token = null;
      state.fullname = null;
      state.refresh_token = null;
      state.role = Roles.AUTH;

      window.location.pathname = '/login';
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const {
  setUser,
  setLogout,
  setPhoneNumber,
  setResetPasswordToken,
  setRole,
  setModeratorStepOne,
  setModeratorStepTwo,
  setWaiting,
} = authSlice.actions;

export default authSlice.reducer;
