/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import {
  FirstFormWrap,
  PrimaryButton,
  SecondaryButton,
  StyledMenu,
  StyledSelect,
  StyledTextField,
  TextFieldBtn,
} from './first-form.s';
import { useFormik, FormikProvider, FormikErrors } from 'formik';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../../assets/icons';
import colors from '../../../../../constants';
import { useAddResourceToTopicMutation } from '../../../../../store/services/moderatorPlansApi';
import { Common } from '../../../../../components';
import { Button, InputAdornment, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FileWithPath } from 'react-dropzone';
import { StyledTable, StyledTableContainer, TableContentWrap } from '../../topicResource.s';
import mock from '../../../../../mock';

interface IFirstFormProps {
  theme: string;
}

interface IFormData {
  files: FileWithPath[];
  descriptions: { [key: number]: string };
}

interface INewResourcesInfo {
  number: number;
  name: string;
  type: string;
  size: string;
}

export const FirstForm: FC<IFirstFormProps> = ({ theme }) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const number = parseInt(parts[parts.length - 2]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addResource, { isLoading }] = useAddResourceToTopicMutation();
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const mockData = mock.resourceTypes;
  const conditionValue = 'plans.viewAddItem.body.resAdd.select.others';
  const [extraDescriptions, setExtraDescriptions] = useState<{ [key: number]: string }>({});

  const bytesToMegabytes = (bytes: number) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  };

  const initialValues: IFormData = {
    files: [],
    descriptions: {},
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      files: Yup.array().min(1, 'Iltimos, rasm faylni yuklang'),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();

        files.forEach((file, index) => {
          formData.append(`file[${index + 1}]`, file);
          const descriptionValue = extraDescriptions[index] || values.descriptions[index] || '';
          formData.append(`desc[${index + 1}]`, descriptionValue);
        });

        await addResource({ id: number, body: formData });
        setSnackbarMessage('mainPage.snackbar.sampleAdded');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate(`/plan-view/${number}`);
        }, 2000);
      } catch (error: any) {
        console.log('error', error);
        setSnackbarMessage('mainPage.snackbar.sampleErr');
        setSnackbarOpen(true);
      }
    },
    validateOnChange: true,
  });

  const renderError = (error: string | string[] | FormikErrors<FileWithPath>[]) => {
    if (typeof error === 'string') {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(', ');
    } else {
      return 'Invalid error format';
    }
  };

  const handleFileChange = (newFiles: FileWithPath[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    formik.setValues({
      ...formik.values,
      files: [...files, ...newFiles],
      descriptions: {
        ...formik.values.descriptions,
        ...newFiles.reduce((acc, _, index) => ({ ...acc, [files.length + index]: '' }), {}),
      },
    });
  };

  const handleDescriptionChange = (index: number, value: string) => {
    formik.setValues({
      ...formik.values,
      descriptions: { ...formik.values.descriptions, [index]: value },
    });

    if (value === conditionValue) {
      setExtraDescriptions((prev) => ({
        ...prev,
        [index]: prev[index] || '',
      }));
    } else {
      setExtraDescriptions((prev) => ({
        ...prev,
        [index]: '',
      }));
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  
    const updatedDescriptions = { ...formik.values.descriptions };
    delete updatedDescriptions[index];
  
    const updatedExtraDescriptions = { ...extraDescriptions };
    delete updatedExtraDescriptions[index];
  
    formik.setValues({
      ...formik.values,
      files: updatedFiles,
      descriptions: updatedDescriptions,
    });
  
    setExtraDescriptions(updatedExtraDescriptions);
  };

  const filesRow = files.map((file, index): INewResourcesInfo => {
    return {
      number: index + 1,
      name: file.name,
      type: file.type,
      size: `${bytesToMegabytes(file.size)} MB`,
    };
  });

  return (
    <FirstFormWrap onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Styles.Row>
          <Common.Uploader
            icon={
              <Icons.FileText
                color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                width={29}
                height={28}
              />
            }
            type='file'
            title={t('resources.add.body.inputs.placeholder.file.main')}
            requiredText={t('resources.add.body.inputs.placeholder.file.secondary')}
            files={files}
            setFiles={handleFileChange}
          />
          {formik.touched.files && formik.errors.files && (
            <Typography.paragraph color='danger-500'>
              {renderError(formik.errors.files)}
            </Typography.paragraph>
          )}
        </Styles.Row>
        {filesRow.length > 0 && (
          <Styles.Row>
            <Common.Container>
              <TableContentWrap>
                <StyledTableContainer>
                  <StyledTable aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          {t('resources.add.table.content.number')}
                        </TableCell>
                        <TableCell align='center'>
                          {t('resources.add.table.content.name')}
                        </TableCell>
                        <TableCell align='center'>
                          {t('resources.add.table.content.type')}
                        </TableCell>
                        <TableCell align='center'>
                          {t('resources.add.table.content.size')}
                        </TableCell>
                        <TableCell align='center'>
                          {t('resources.add.table.content.resourceType')}
                        </TableCell>
                        <TableCell align='center'>{t('resources.table.necessary')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filesRow.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell align='center'>{row.number}</TableCell>
                          <TableCell align='center'>{row.name}</TableCell>
                          <TableCell align='center'>{row.type}</TableCell>
                          <TableCell align='center'>{row.size}</TableCell>
                          <TableCell align='center'>
                            {formik.values.descriptions[index] === conditionValue ? (
                              <StyledTextField
                                value={extraDescriptions[index] || ''}
                                onChange={(e) =>
                                  setExtraDescriptions((prev) => ({
                                    ...prev,
                                    [index]: e.target.value,
                                  }))
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <TextFieldBtn
                                        onClick={() => {
                                          formik.setValues({
                                            ...formik.values,
                                            descriptions: { ...formik.values.descriptions, [index]: '' },
                                          });
                                          setExtraDescriptions((prev) => ({
                                            ...prev,
                                            [index]: '',
                                          }));
                                        }}
                                      >
                                        <Icons.X color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}/>
                                      </TextFieldBtn>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            ) : (
                              <StyledSelect
                                defaultValue={0}
                                onChange={(e) =>
                                  handleDescriptionChange(index, e.target.value as string)
                                }
                                value={formik.values.descriptions[index] || ''}
                                name='planLesson'
                                MenuProps={{
                                  disableScrollLock: true,
                                  sx: {
                                    height: '280px',
                                    '& .MuiPaper-root': {
                                      overflowY: 'auto',
                                      scrollbarWidth: 'thin',
                                      scrollbarColor: '#bdbdbd transparent',
                                    },
                                  },
                                }}>
                                {mockData.map((item: any) => (
                                  <StyledMenu value={item.value}>{t(item.value)}</StyledMenu>
                                ))}
                              </StyledSelect>
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            <Button onClick={() => handleRemoveFile(index)}>
                              <Icons.Trash
                                color={
                                  theme === 'dark' ? colors['grey-300'] : colors['typography-body']
                                }
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </TableContentWrap>
            </Common.Container>
          </Styles.Row>
        )}

        <Styles.Row gap={16} content={'space-between'} wrap={'nowrap'}>
          <SecondaryButton
            variant='contained'
            onClick={() => navigate(`/plan-view/${number}`)}
            disabled={isLoading}>
            <Typography.h6 color='secondary-500'>
              {t('plans.viewAddItem.body.button.previous')}
            </Typography.h6>
          </SecondaryButton>

          <PrimaryButton type='submit' variant='contained' disabled={isLoading}>
            <Typography.h6 color='white'>{t('plans.viewAddItem.body.button.next')}</Typography.h6>
          </PrimaryButton>
        </Styles.Row>
      </FormikProvider>

      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </FirstFormWrap>
  );
};
