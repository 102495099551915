/* eslint-disable react/jsx-pascal-case */
import { FC, useRef, useState } from 'react';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  BtnWrapper,
  CodeWrapper,
  LinkWrapper,
  PasswordVerificationFormWrap,
  PrimaryButton,
  SecondaryButton,
  StyledTextField,
  TitleWrapper,
} from './Verification-form.s';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResendPasswrodMutation } from '../../../../store/services/updatePasswordApi';
import Icons from '../../../../assets/icons';

interface IVerificationFormProps {
  onSubmit: (code: string, phoneNumber: string) => Promise<void>;
  initialValues: {
    code: string;
  };
  validationSchema: Yup.ObjectSchema<any>;
  onSuccess: () => void;
  onError: (error: any) => void;
  back: string;
}

export const VerificationForm: FC<IVerificationFormProps> = ({
  onSubmit,
  initialValues,
  validationSchema,
  onSuccess,
  onError,
  back,
}) => {
  const [t, i18n] = useTranslation('global');
  const [resendPassword] = useResendPasswrodMutation();
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>(new Array(4).fill(null));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const phoneNumber = useSelector((state: RootState) => state.auth.phone);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        await onSubmit(values.code, phoneNumber);
        onSuccess();
      } catch (error: any) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.data.detail);
      }
    },
  });

  const focusInput = (index: number) => {
    inputRefs.current[index]?.focus();
  };

  const selectInput = (index: number) => {
    inputRefs.current[index]?.select();
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      if (formik.values.code[index] === '' && index > 0) {
        focusInput(index - 1);
        selectInput(index - 1);
      } else {
        const newOtp = formik.values.code.split('');
        newOtp[index] = '';
        formik.setFieldValue('code', newOtp.join(''));
        if (index > 0) {
          focusInput(index - 1);
          selectInput(index - 1);
        }
      }
    } else if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault();
      focusInput(index - 1);
      selectInput(index - 1);
    } else if (event.key === 'ArrowRight' && index < 3) {
      event.preventDefault();
      focusInput(index + 1);
      selectInput(index + 1);
    }
  };

  const handleChange = (index: number, value: string) => {
    if (!isNaN(parseInt(value, 10))) {
      const newOtp = formik.values.code.split('');
      newOtp[index] = value;
      formik.setFieldValue('code', newOtp.join(''));
      if (index < 3 && value !== '') {
        focusInput(index + 1);
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text/plain').substring(0, 4).trim();
    const newOtp = formik.values.code.split('');
    for (let i = index; i < 4 && pastedText.length; i++) {
      newOtp[i] = pastedText.charAt(0);
      pastedText = pastedText.substring(1);
    }
    formik.setFieldValue('code', newOtp.join(''));
    if (index + pastedText.length < 4) {
      focusInput(index + pastedText.length);
    }
  };

  const handleResend = async () => {
    try {
      await resendPassword({ phone: phoneNumber, lang: i18n.language }).unwrap();
    } catch (error: any) {
      setSnackbarMessage(error.data.message);
      setSnackbarOpen(true);
    }
  };

  let lastFourDigits = phoneNumber.substring(phoneNumber.length - 4);

  return (
    <PasswordVerificationFormWrap>
      <Styles.Row gap={26}>
        <TitleWrapper gap={6}>
          <Styles.Column size={12} difference={0}>
            <Typography.h3 color='typography-body'>{t('auth.passVer.title')} 💬</Typography.h3>
          </Styles.Column>
          <Styles.Column size={12} difference={0}>
            <Typography.paragraph color='typography-body'>
              {t('auth.passVer.subTitleStart')}{' '}
              <span style={{ fontWeight: 600 }}>+998 ** *** {lastFourDigits}</span>{' '}
              {t('auth.passVer.subTitleEnd')}
            </Typography.paragraph>
          </Styles.Column>
        </TitleWrapper>
        <form onSubmit={formik.handleSubmit}>
          <Styles.Row gap={16}>
            <CodeWrapper gap={4}>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.paragraphBold color='typography-body'>
                  {t('auth.passVer.passTitle')}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row gap={6} content={'center'}>
                {Array.from({ length: 4 }, (_, index) => (
                  <StyledTextField
                    key={index}
                    variant='outlined'
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                    }}
                    value={formik.values.code[index] || ''}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) =>
                      handleKeyDown(index, e as React.KeyboardEvent<HTMLInputElement>)
                    }
                    onClick={() => selectInput(index)}
                    onPaste={(e) => handlePaste(e as React.ClipboardEvent<HTMLInputElement>, index)}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </Styles.Row>
              {formik.errors.code && (
                <Styles.Row>
                  <Typography.h6 color='danger-500'>
                    {t('auth.passVer.errors.code.wrong')}
                  </Typography.h6>
                </Styles.Row>
              )}
            </CodeWrapper>

            <BtnWrapper gap={10} content={{ xs: 'space-between', md: 'center' }}>
              <SecondaryButton variant='text' onClick={() => navigate(back)}>
                <Typography.h6 color='primary-500'>{t('auth.passVer.cancelBtn')}</Typography.h6>
              </SecondaryButton>
              <PrimaryButton type='submit' variant='contained' disabled={formik.isSubmitting}>
                <Styles.Row gap={10} align_items={'center'} content={'center'} wrap={'nowrap'}>
                  {formik.isSubmitting ? (
                    <Icons.Loader />
                  ) : (
                    <Typography.h6 color='white'>{t('auth.passVer.nextBtn')}</Typography.h6>
                  )}
                </Styles.Row>
              </PrimaryButton>
            </BtnWrapper>

            <LinkWrapper content={'center'} gap={6}>
              <Typography.placeholderDefault color='typography-body'>
                {t('auth.passVer.resendInTitle')}
              </Typography.placeholderDefault>
              <Typography.placeholderDefault onClick={handleResend} color='primary-500'>
                {t('auth.passVer.resendInLink')}
              </Typography.placeholderDefault>
            </LinkWrapper>
          </Styles.Row>
        </form>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </PasswordVerificationFormWrap>
  );
};
