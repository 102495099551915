import styled from 'styled-components';
import Styles, { Typography } from '../../../styles';
import { Box, Button, MenuItem, Select, Table, TableContainer, TextField } from '@mui/material';
import colors from '../../../constants';

export const PlansViewWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const ModalButton = styled(Button)`
  && {
    width: 192px;
    padding: 10px;
    text-transform: capitalize;
    @media(max-width: 768px){
      width: 150px;
    }
  }
`;

export const StyledTable = styled(Table)`
  min-width: 650px;
  width: 100%;
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const ChatTitleWrapper = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const ChatWrapper = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const StyledColumn = styled(Styles.Column)`
  && {
    align-self: flex-end;
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
    max-width: 100%;
    overflow-x: auto;
  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const BtnWrap = styled(Styles.Row)`
  padding: 0px 16px 0px 16px;
`;

export const PlanBannerWrapper = styled(Styles.Row)`
  && {
    background-color: #a8aaae29;
    border-radius: 6px;
    width: 300px;
    height: 300px;
  }
`;

export const PlanInfoWrapper = styled(Styles.Row)`
  && {
    padding: 0px 10px;
  }
`;

export const StyledBox = styled(Box)<{ selectedImage: string }>`
  && {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-image: ${({ selectedImage }) => `url(${selectedImage})`};
  }
`;

export const Line = styled(Styles.Row)`
  && {
    height: 1px;
    background-color: ${colors['grey-300']};
  }
`;

export const EmptyTypo = styled(Typography.h4)`
  && {
    text-align: center;
    padding: 6px;
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    height: 44px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const PrimaryIconBtn = styled(Button)`
  && {
    width: auto;
    height: 45px;
    background-color: ${colors['primary-500']};
    @media (max-width: 1000px) {
      min-width: 44px;
      padding: 6px 10px;
    }
  }
`;

export const SecondaryIconBtn = styled(Button)`
  && {
    width: auto;
    height: 45px;
    background-color: ${colors['opacity-primary32']};
    &:hover {
      background-color: ${colors['grey-200']};
    }
    @media (max-width: 1000px) {
      min-width: 44px;
      padding: 6px 10px;
    }
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 100%;
    height: 45px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;
