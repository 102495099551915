import styled from 'styled-components';
import colors from '../../../../constants';
import Styles from '../../../../styles';
import { Button, Chip, MenuItem, Table, TableContainer } from '@mui/material';

export const PlansWrap = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
    @media(max-width: 768px) {
      padding: 0px;
    }
  }
`;

export const DownloadBtnWrapper = styled(Styles.Row)`
  && {
    cursor: not-allowed;
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; /* Цвет фокуса */
      color: ${colors['white']} !important; /* Цвет текста при фокусе */
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important; 
    }
  }
`;

export const HeaderBtnWrapper = styled(Styles.Row)`
  && {
    padding: 0px 5px;

  }
`;

export const SeletsWrapper = styled(Styles.Row)`
  && {
    padding: 0px 5px;

  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const PrimaryButton = styled(Button)<{ buttonState: boolean }>`
  && {
    border-radius: 6px;
    border-color: ${props => props.buttonState  ? colors['primary-400'] : colors['secondary-400']};
    text-transform: none;
    color: ${props => props.buttonState  ? colors['primary-400'] : colors['secondary-400']};
  }
  &&:hover {
    color: ${colors['primary-400']};
  }
`;

export const SecondaryButton = styled(Button)<{ buttonState: boolean }>`
  && {
    border-radius: 6px;
    border-color: ${props => props.buttonState ? colors['secondary-400'] : colors['primary-400']};
    text-transform: none;
    color: ${props => props.buttonState ? colors['secondary-400'] : colors['primary-400']};

  }
  &&:hover {
    color: ${colors['primary-400']};
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;

export const ScienceChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['primary-500']};
    background-color: ${colors['opacity-primary32']};
  }
`;

export const StateChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['success-500']};
    background-color: ${colors['opacity-success32']};
  }
`;

export const EmptyChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['danger-500']};
    background-color: ${colors['opacity-danger32']};
  }
`;