import styled from 'styled-components';
import Styles from '../../../../../styles';
import colors from '../../../../../constants';
import { Button, Chip, MenuItem, Select, Table, TableContainer } from '@mui/material';

export const ThemeFormWrap = styled.form`
  width: 100%;
`;

export const FormWrap = styled(Styles.Row)`
  padding: 24px;
`;

export const UserFormInput = styled(Styles.Row)`
  && {
    justify-content: center;
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    gap: 16px;
    &.Mui-selected {
      color: ${colors['white']} !important;
      & svg {
        fill: ${colors['white']} !important;
        stroke: ${colors['white']} !important;
      }
    }
    &:focus {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
      & svg {
        fill: ${colors['white']} !important;
        stroke: ${colors['white']} !important;
      }
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
      & svg {
        fill: ${colors['white']} !important;
        stroke: ${colors['white']} !important;
      }
    }
  }
`;

export const UserButtons = styled(Styles.Row)`
  && {
    padding: 24px;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
`;

export const ApplicationFormTitle = styled(Styles.Row)`
  && {
    padding: 24px 24px 0px 24px;
    flex-direction: column;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 100%;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .MuiSelect-select {
      display: flex;
      gap: 16px;
      align-items: center;
      overflow-x: auto;
      scrollbar-width: thin; /* Для Firefox */
      scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Цвет скроллбара и фона */
    }

    /* Для WebKit браузеров (Chrome, Safari) */
    .MuiSelect-select::-webkit-scrollbar {
      width: 6px; /* Ширина вертикального скроллбара */
      height: 6px; /* Высота горизонтального скроллбара */
    }

    .MuiSelect-select::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2); /* Цвет скроллбара */
      border-radius: 3px; /* Закругление углов */
    }

    .MuiSelect-select::-webkit-scrollbar-track {
      background: transparent; /* Цвет фона скроллбара */
    }
  }
`;

export const StyledMultiSelect = styled(Select)`
  && {
    width: 100%;
    max-width: 450px;

    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .MuiSelect-select {
      display: flex;
      gap: 16px;
      align-items: center;
      overflow-x: scroll !important;

      ::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;
      scrollbar-color: transparent transparent;
    }
  }
`;

export const StyledButton = styled(Button)<{ activeButton: number | null; item: number }>`
  && {
    width: 58px;
    height: 38px;
    border-radius: 6px;
    text-transform: capitalize;
    box-shadow: none;
    background-color: ${({ activeButton, item }) =>
      activeButton === item ? colors['primary-500'] : colors['opacity-primary16']};
  }
`;

export const NavbarFlag = styled.img`
  width: 25px;
  height: 24px;
  border-radius: 100px;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
    @media (max-width: 576px) {
      padding: 0px 10px;
    }
  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const SelectWrapper = styled(Styles.Row)`
  && {
    width: 200px;
    @media (max-width: 576px) {
      width: 100px;
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;

export const ScienceChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['secondary-500']};
    background-color: ${colors['opacity-secondary32']};
  }
`;

export const StateChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['success-500']};
    background-color: ${colors['opacity-success32']};
  }
`;

export const EmptyChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['danger-500']};
    background-color: ${colors['opacity-danger32']};
  }
`;
