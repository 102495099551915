import Icons from '../assets/icons';

export const Settings = {
  userBtns: [
    {
      label: 'settings.title.account',
      icons: Icons.Users,
      formChange: 'account',
    },
    {
      label: 'settings.title.theme',
      icons: Icons.Brush,
      formChange: 'theme',
    },
    {
      label: 'settings.title.moderator',
      icons: Icons.ChartBat,
      formChange: 'moderator',
    },
  ],
  authorBtns: [
    {
      label: 'settings.title.account',
      icons: Icons.Users,
      formChange: 'account',
    },
    {
      label: 'settings.title.theme',
      icons: Icons.Brush,
      formChange: 'theme',
    },
    {
      label: 'settings.title.doc',
      icons: Icons.FileText,
      formChange: 'doc',
    },
    {
      label: 'settings.title.application',
      icons: Icons.UserCheck,
      formChange: 'application',
    },
    {
      label: 'settings.title.tmr',
      icons: Icons.ChartDots,
      formChange: 'tmr',
    },
  ],
  fontSize: [
    {
      size: 14,
    },
    {
      size: 16,
    },
    {
      size: 18,
    },
    {
      size: 20,
    },
  ],
  tmrFormTable: [
    { key: 'number', width: '90px', align: 'left' },
    { key: 'classType', width: '170px', align: 'left' },
    { key: 'grade', width: '170px', align: 'left' },
    { key: 'lesson', width: '170px', align: 'left' },
    { key: 'lessonType', width: '100%', align: 'center' },
    { key: 'file', width: '100%', align: 'center' },
    { key: 'status', width: '170px', align: 'center' },
  ] as const,
  applicationFormTable: [
    { key: 'number', width: '90px', align: 'left' },
    { key: 'classType', width: '170px', align: 'left' },
    { key: 'grade', width: '170px', align: 'left' },
    { key: 'lesson', width: '170px', align: 'left' },
    { key: 'lessonType', width: '100%', align: 'center' },
    { key: 'status', width: '170px', align: 'center' },
  ] as const,
  accountForm: [
    { key: 'classType', width: '100%', align: 'center' },
    { key: 'lesson', width: '100%', align: 'center' },
    { key: 'lessonType', width: '100%', align: 'center' },
  ] as const,
  filterStatus: [
    { key: 'accepted', value: 'settings.tmrTitle.table.accepted'},
    { key: 'rejected', value: 'settings.tmrTitle.table.rejected'},
    { key: 'pending', value: 'settings.tmrTitle.table.pending'},
  ],
};
