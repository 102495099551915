/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import {
  FormWrap,
  StyledTextField,
  ThemeFormWrap,
  TmrFormTitle,
  UserButtons,
  UserFormInput,
} from './tmr-add-form.s';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import colors from '../../../../../constants';
import Icons from '../../../../../assets/icons';
import { useAppTheme } from '../../../../../hooks';
import { Common } from '../../../../../components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useAddTmrFileMutation,
  useGetTmrDataByIdQuery,
} from '../../../../../store/services/changeModerator';

interface ITmrAddFormProps {
  setAddStatus: (status: boolean) => void;
  tmrID: number;
}

export const TmrAddForm: FC<ITmrAddFormProps> = ({ setAddStatus, tmrID }) => {
  const [t, i18n] = useTranslation('global');
  const { theme, setTheme } = useAppTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [planAppeal, { isLoading }] = useAddTmrFileMutation();
  const { data, isFetching: plansLoading } = useGetTmrDataByIdQuery(tmrID);

  const formik = useFormik({
    initialValues: {
      userFile: [],
    },
    validationSchema: Yup.object({
      userFile: Yup.array().required(t('mainPage.mainModal.errors.subject')),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();

        values.userFile.forEach((file: File) => {
          formData.append('file', file);
        });
        formData.append('tmr_appeal', tmrID.toString());
        await planAppeal({
          payload: formData,
          lang: i18n.language,
        }).unwrap();

        setSnackbarOpen(true);
        setSnackbarMessage('mainPage.snackbar.sampleAdded');
        formik.handleReset(undefined);
        setAddStatus(false);
      } catch (error: any) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.data.error || 'mainPage.snackbar.sampleErr');
      }
    },
  });

  return (
    <ThemeFormWrap onSubmit={formik.handleSubmit}>
      <Styles.Row>
        <TmrFormTitle gap={8}>
          <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('settings.tmrTitle.title')}
          </Typography.h5>
          <Typography.paragraph color='typography-muted'>
            {t('settings.tmrTitle.subTitle')}
          </Typography.paragraph>
        </TmrFormTitle>
        <FormWrap gap={16}>
          <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', md: 'row' }}>
            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.class_group')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledTextField
                  id='text'
                  name='text'
                  type='text'
                  disabled
                  placeholder='-'
                  value={data?.results[0]?.class_groups}
                />
              </UserFormInput>
            </Styles.Row>
            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.table.select.grade')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledTextField
                  id='text'
                  name='text'
                  type='text'
                  disabled
                  placeholder='-'
                  value={data?.results[0]?.classes}
                />
              </UserFormInput>
            </Styles.Row>

            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.lesson')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledTextField
                  id='text'
                  name='text'
                  type='text'
                  disabled
                  placeholder='-'
                  value={data?.results[0]?.science}
                />
              </UserFormInput>
            </Styles.Row>

            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.grade')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledTextField
                  id='text'
                  name='text'
                  type='text'
                  disabled
                  placeholder='-'
                  value={data?.results[0]?.science_type}
                />
              </UserFormInput>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row>
            <Common.Uploader
              icon={<Icons.Upload color={colors['typography-body']} />}
              type='file'
              title='settings.tmrTitle.uploaderTitle'
              requiredText='auth.authorReg.rating.uploader.subtitle'
              files={formik.values.userFile}
              singleFile={true}
              setFiles={(files) => {
                formik.setFieldValue('userFile', true ? files : [...formik.values.userFile, ...Array.from(files)]);
              }}
            />
          </Styles.Row>
        </FormWrap>
        <UserButtons gap={16} wrap={'nowrap'}>
          <Styles.Row width='130px'>
            <Button
              variant='text'
              onClick={(e) => {
                formik.handleReset(e);
                setAddStatus(false);
              }}
              sx={{
                width: '150px',
                padding: '10px',
                backgroundColor: `${colors['opacity-primary16']}`,
              }}>
              <Typography.paragraphSmallSemiBold color='primary-400'>
                {t('auth.passVer.cancelBtn')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
          <Styles.Row width='130px'>
            <Button
              disabled={isLoading}
              type='submit'
              variant='contained'
              sx={{
                width: '150px',
                padding: '10px',
              }}>
              <Typography.paragraphSmallSemiBold color='white'>
                {t('settings.buttons.save')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
        </UserButtons>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </ThemeFormWrap>
  );
};
