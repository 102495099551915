import { Button } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../constants';
import Styles, { Typography } from '../../../styles';
import { motion } from 'framer-motion';

interface MotionSelectProps {
  inView: boolean;
  index?: number;
}

const wrapperHover = {
  initial: { scale: 1 },
  hover: { scale: 1.02 },
};

const wrapperPrimaryFirst = {
  hidden: { opacity: 0, scale: 0.8, x: -25 },
  visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.6, ease: 'circOut' } },
};

const wrapperPrimarySecond = {
  hidden: { opacity: 0, scale: 0.8, x: 25 },
  visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.6, ease: 'circOut' } },
};

const wrapperStartTitle = {
  hidden: { opacity: 0, y: -15 },
  visible: { opacity: 1, y: 0, transition: { duration: 1.0, ease: 'circOut' } },
};

const wrapperEndTitle = {
  hidden: { opacity: 0, y: 15 },
  visible: { opacity: 1, y: 0, transition: { duration: 1.0, ease: 'circOut' } },
};

export const SelectUserWrap = styled.div`
  && {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    background-color: ${colors['opacity-primary40']};
    @media (max-width: 768px) {
      width: 150px;
    }
    z-index: 1;
  }
`;

export const MotionSelect = styled(motion.div).attrs<MotionSelectProps>(({ inView, index }) => ({
  variants: index === 0 ? wrapperPrimaryFirst : wrapperPrimarySecond,
  initial: 'hidden',
  animate: inView ? 'visible' : 'hidden',
}))<MotionSelectProps>`
  && {
  }
`;

export const MotionStartTitle = styled(motion.div).attrs<MotionSelectProps>(({ inView }) => ({
  variants: wrapperStartTitle,
  initial: 'hidden',
  animate: inView ? 'visible' : 'hidden',
}))<MotionSelectProps>`
  && {
  }
`;

export const MotionEndTitle = styled(motion.div).attrs<MotionSelectProps>(({ inView }) => ({
  variants: wrapperEndTitle,
  initial: 'hidden',
  animate: inView ? 'visible' : 'hidden',
}))<MotionSelectProps>`
  && {
  }
`;

export const SelectWrap = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperHover,
  initial: 'initial',
  whileHover: 'hover',
}))`
  && {
    width: 305px;
    gap: 16px;
    border: 1px solid #dbdade;
    border-radius: 6px;
    padding: 24px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translateZ(0);
    will-change: transform;
  }
`;

export const MotionTypographyH4 = styled(motion(Typography.h4)).attrs(() => ({
  variants: wrapperHover,
  initial: 'initial',
  whileHover: 'hover',
}))`
  && {
  }
`;

export const MotionSpan = styled(motion.span).attrs(() => ({
  variants: wrapperHover,
  initial: 'initial',
  whileHover: 'hover',
}))`
  && {
  }
`;

export const TypographyTitle = styled(Typography.paragraphSmall)<{ index: number }>`
  && {
    width: ${(props) => (props.index === 1 ? 'auto' : '180px')};
  }
`;


export const LinksWrapper = styled(Styles.Row)`
  && {
    margin-top: 20px;
  }
`;

export const LinkTypo = styled(Typography.paragraphMedium)`
  && {
    cursor: pointer;
    text-align: center;
  }
`
export const LinkTypoAndIcons = styled(Typography.paragraphMedium)`
  && {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
  }
`