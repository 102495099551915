import './index.css';
import App from './App';
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { FileProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <FileProvider>
          <App />

          <ToastContainer
            position='top-center'
            autoClose={3000}
            hideProgressBar
            closeOnClick
            pauseOnHover
            icon={false}
            draggable
            style={{ textAlign: 'center' }}
          />
        </FileProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
