/* eslint-disable react/jsx-pascal-case */
import React, { FC, useEffect } from 'react';
import { AccordionContainer } from '../accordion/Accordion';
import CommonList from '../../../../../../../../../../components/common';
import { useGetTopicCalculationByParamsQuery } from '../../../../../../../../../../store/services/topicApi';
import { EmptyTitleWrapper } from './resourcesModal.s';
import { Typography } from '../../../../../../../../../../styles';
import { useAppTheme } from '../../../../../../../../../../hooks';
import { useTranslation } from 'react-i18next';

interface IResourcesModal {
  theme: string;
  modalStatus: boolean;
  setModalStatus: (status: boolean) => void;
  dateByIndex: string;
  resourcesData: {
    class: number | null;
    science: number | null;
    week: number | null;
    schedule: number | null;
  };
}

export const ResourcesModal: FC<IResourcesModal> = ({
  modalStatus,
  setModalStatus,
  dateByIndex,
  resourcesData,
}) => {
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');
  const shouldFetch =
    resourcesData.science !== null &&
    resourcesData.class !== null &&
    resourcesData.schedule !== null;
  const {
    data: serverData,
    refetch,
    isLoading,
    isSuccess,
  } = useGetTopicCalculationByParamsQuery(
    {
      science: resourcesData.science,
      classes: resourcesData.class,
      week: resourcesData.week,
      schedule: resourcesData.schedule,
    },
    {
      skip: !shouldFetch, 
    }
  );

  useEffect(() => {
    if (modalStatus && resourcesData.class && resourcesData.science && dateByIndex) {
      refetch();
    }
  }, [modalStatus, resourcesData.class, resourcesData.science, dateByIndex, refetch]);

  const itemToDisplay =
    isSuccess && serverData && serverData.length > 0 ? serverData[0] : undefined;

  return (
    <CommonList.Modal modalStatus={modalStatus} setModalStatus={setModalStatus}>
      {isLoading ? (
        <EmptyTitleWrapper content={'center'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('plans.main.loadingTitle')}
          </Typography.h4>
        </EmptyTitleWrapper>
      ) : itemToDisplay === undefined ? (
        <EmptyTitleWrapper content={'center'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('mainPage.resoursModal.empty')}
          </Typography.h4>
        </EmptyTitleWrapper>
      ) : (
        <AccordionContainer item={itemToDisplay} />
      )}
    </CommonList.Modal>
  );
};
