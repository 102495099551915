import i18next from 'i18next';
import './styles/variables.css';
import { useEffect } from 'react';
import { authRoute, Router } from './routes';
import colors from './constants';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from './store/store';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { setLogout, setUser } from './store/slices/authSlice';
import global_uz from './translations/uz/global.json';
import global_ru from './translations/ru/global.json';
import { useGetMeQuery } from './store/services/authApi';
import { darkTheme, lightTheme } from './constants/muiConfig';
import { useAppTheme } from './hooks';
import { removeToken } from './helpers/token';

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('lang') || 'uz',
  resources: {
    uz: {
      global: global_uz,
    },
    ru: {
      global: global_ru,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const { data: userData, isLoading } = useGetMeQuery(null);
  const userState = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { theme } = useAppTheme();
  
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (userData) {
      if (userData.is_contracted === false) {
        handleLogout();
      } else if (userData.role !== 'auth') {
        dispatch(
          setUser({
            id: userData.id,
            first_name: userData.first_name,
            father_name: userData.father_name,
            last_name: userData.last_name,
            phone: userData.phone,
            password: userData.password,
            avatar: userData.avatar,
            region: userData.region,
            district: userData.district,
            institution: userData.institution,
            institution_number: userData.institution_number,
            science_group: userData.science_group,
            role: userData.role,
            resource_creatable: userData.resource_creatable,
            plan_creatable: userData.plan_creatable,
            topic_creatable: userData.topic_creatable,
          })
        );
        if (authRoute.some((route) => route.path === currentPath)) {
          navigate('/main');
        }
      }
    }
  }, [dispatch, userData]);

  const handleLogout = () => {
    dispatch(setLogout());
    removeToken();
    navigate('/login');
  };

  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  useEffect(() => {
    const fontSize = Number(localStorage.getItem('fontSize')) || 16;
    document.documentElement.style.setProperty('--font-size', `${fontSize}px`);
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: {
              backgroundColor:
                theme === 'light'
                  ? `${colors.white} !important`
                  : `${colors['dark-theme-primary']} !important`,
            },
            'input:-webkit-autofill': {
              WebkitBoxShadow:
                theme === 'light'
                  ? '0 0 0px 1000px white inset'
                  : '0 0 0px 1000px rgba(47, 51, 73, 1) inset',
            },
          }}
        />
        {isLoading ? <></> : <Router />}
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
